import axios from "axios"
import { useEffect, useState } from "react"
import { UserModel } from "../../model/User.model"
import { DeleteHook, get, post, put, setKeys } from "../../services/Base.service"

export const getMyUserFromApi = async (): Promise<UserModel> => {
    return get(`${process.env.REACT_APP_USER_MANAGER_URL}/user/me`)
}

export function useGetUsers<S>() {
    const [data, setData] = useState<S | S[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const use = () => {
        setLoading(true);
        const fetchData = () => {
            try {
                axios(`${process.env.REACT_APP_USER_MANAGER_URL}/user`)
                    .then(result => {
                        setData(setKeys(result.data))
                        setLoading(false);
                    })
            } catch (error) {
                setError(true);
            }
        };
        fetchData();
    };

    useEffect(use, []);

    return { data, loading, error, reuse: use };
}

export function useGetRoles<S>() {
    const [data, setData] = useState<S | S[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const use = () => {
        setLoading(true);
        const fetchData = () => {
            try {
                axios(`${process.env.REACT_APP_USER_MANAGER_URL}/user/roles`)
                    .then(result => {
                        setData(result.data)
                        setLoading(false);
                    })
            } catch (error) {
                setError(true);
            }
        };
        fetchData();
    };

    useEffect(use, []);

    return { data, loading, error, reuse: use };
}

export function useGetStatuses<S>() {
    const [data, setData] = useState<S | S[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const use = () => {
        setLoading(true);
        const fetchData = () => {
            try {
                axios(`${process.env.REACT_APP_USER_MANAGER_URL}/user/states`)
                    .then(result => {
                        setData(result.data)
                        setLoading(false);
                    })
            } catch (error) {
                setError(true);
            }
        };
        fetchData();
    };

    useEffect(use, []);

    return { data, loading, error, reuse: use };
}

export function useUpdateUser() {
    const [data, setData] = useState<UserModel>();
    const [loading, setLoading] = useState(false);
    const use = (requestBody: UserModel) => {
        setLoading(true);
        try {
            return put<UserModel>(`${process.env.REACT_APP_USER_MANAGER_URL}/user`, requestBody)
                .then((result: UserModel) => {
                    setData(result)
                    setLoading(false);
                })
        } catch (error) {
            setError(true);
        }
    };
    const [error, setError] = useState(false);

    return { data, loading, error, use }
}

export function useUpdatePassword() {
    const [data, setData] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const use = (userId: string, newPass: string) => {
        setLoading(true);
        try {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_USER_MANAGER_URL}/user/pass?userId=${userId}`,
                data: newPass,
                headers: { "Content-Type": "text/plain" }
            })
                .then(response => response.data)
                .then((_: any) => {
                    setData(true)
                    setLoading(false);
                })
        } catch (error) {
            setError(true);
        }
    };
    const [error, setError] = useState(false);

    return { data, loading, error, use }
}

export function useCreateUser() {
    const [data, setData] = useState<UserModel>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const use = (requestBody: UserModel) => {
        console.log("User to save:")
        console.log(requestBody)
        setLoading(true);
        try {
            return post<UserModel>(`${process.env.REACT_APP_USER_MANAGER_URL}/user`, requestBody)
                .then((result: UserModel) => {
                    setData(result);
                    setLoading(false);
                    return result;
                });
        } catch (error) {
            setError(true);
            return Promise.reject(error);
        }
    };
    return { data, loading, error, use }
}



export function useDeleteUser(): DeleteHook {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const use = (id: string) => {
        setLoading(true);
        const runDelete = () => {
            try {
                return axios(
                    {
                        method: "delete",
                        url: `${process.env.REACT_APP_USER_MANAGER_URL}/user?id=${id}`
                    })
                    .then(result => {
                        if (result) {
                            setLoading(false);
                        } else {
                            setError(true);
                        }
                    })
            } catch (error) {
                setError(true);
                return Promise.reject(error);
            }
        };
        return runDelete();
    };

    return { loading, error, use };
}