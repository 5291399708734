import { Badge, Tag } from "antd";
import { useContext } from "react";
import { StatessContext } from "../../services/Ticket.service";
import StateBadgeColor from "./StateBadgeColor";

function TicketStateTag(props: {text: string, state: string}) {

    return ( 
        <Tag className="mb-1" color="#191919">
              <StateBadgeColor state={props.state} /> {props.text}
        </Tag>
     );
}

export default TicketStateTag;