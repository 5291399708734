export class Stuffs{
    public stuff: any = {};

    public putStuff(key: string, stuff: any){
        this.stuff[key] = stuff;
    }

    public getStuff<T>(key: string){
        return this.stuff[key] as T;
    }

    public isExistsStuff(key: string){
        return this.stuff[key] !== undefined;
    }

    public removeStuff(key: string){
        this.stuff[key] = null;
    }
}