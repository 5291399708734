import { message } from "antd";
import { TicketModel } from "../../model/Ticket.model";
import { persistTicket } from "../../services/Ticket.service";
import { Stuffs } from "../../vault/Stuffs";
import { WorkerResponse } from "../../vault/Task";

export const saveTicketWorkerCallBack = (
    stuffs?: Stuffs,
    response?: any | undefined,
    error?: boolean
) => {
    if (!error) {
        message.success(`Ticket sesión ${stuffs?.getStuff("vaultSessionId")} creado correctamente.`);
    }
};

export const saveTicketWorker = async (stuffs: Stuffs) => {
    const ticket: TicketModel = stuffs.getStuff("ticket");

    const response = await persistTicket(ticket)
        .catch((error: any) => {
            console.warn(
                "Error tratando de guardar ticket desde Vault. Se omite para intentarlo más tarde."
            );
            return false;
        });

    if (!response) {
        return WorkerResponse.withError("Error tratando de guardar ticket.");
    } else {
        return WorkerResponse.noError(response);
    }
};