import { Link } from "react-router-dom";
import { TicketModel, TICKET_STATE } from "../../model/Ticket.model";
import { canEditTicket } from "../../services/Auth.service";

const isTicketEvaluacion = (ticket: TicketModel) => {
  const actualState = ticket.actualState || "";
  return actualState === TICKET_STATE.EVALUACION;
};

function EditButton(props: { ticket: TicketModel, className?: string }) {
  return canEditTicket() && isTicketEvaluacion(props.ticket) ? (
    <Link className={"w-auto h-auto" + props.className} to={"/edit/" + props.ticket.id}>
      Editar
    </Link>
  ) : null;
}

export default EditButton;
