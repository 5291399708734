import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { CenterModel } from "../model/CenterModel";
import { get } from "./Base.service";


export const CenterContext = createContext<CenterModel[]>([]);


export function findCenters(name: string = ""): Promise<CenterModel[]> {
   return get<CenterModel[]>(`${process.env.REACT_APP_TICKET_MANAGER_URL}/center/${name}`);
}


export function useCenters() {

   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(false);
   const [centers, setCenters] = useState<any>([]);

   const getCenters = () => {
      setLoading(true);
      const fetchData = () => {

         try {
            axios(`${process.env.REACT_APP_TICKET_MANAGER_URL}/center/`)
               .then((result: any) => {
                  setCenters(result.data);
                  setLoading(false);
               })
         } catch (error) {
            setError(true);
            setLoading(false);
         }

      };
      fetchData();
   };

  // useEffect(reuse, []);

   return { loading, error, getCenters, centers };

}