import { Button, List, Popconfirm } from "antd";
import Search from "antd/lib/input/Search";
import { Fragment, useEffect, useRef, useState } from "react";
import { CenterModel } from "../../model/CenterModel";
import { useDelete } from "../../services/Base.service";
import { findCenters } from "../../services/Center.service";
import { AppstoreAddOutlined } from "@ant-design/icons";
import EditCenter from "./EditCenter";
import { isUserAdmin } from "../../services/Auth.service";
import ErrorComponent from "../../components/error/ErrorComponent";

function SearchCenters() {
  const [data, setData] = useState<CenterModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const deleteHook = useDelete("center");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoadingUpdateCenter, setIsLoadingUpdateCenter] = useState(false);
  const [centerToUpdate, setCenterToUpdate] = useState<CenterModel>();

  useEffect(() => {
    find();
  }, []);

  const find = async () => {
    console.log("Buscando: " + searchTerm);
    setLoading(true);
    findCenters(searchTerm).then((centers: CenterModel[]) => {
      setData(centers);
      setLoading(false);
    });
  };

  return isUserAdmin() ? (
    <div className="col">
      <div className="row mb-3">
        <Search
          onChange={(e) => setSearchTerm(e.target.value)}
          enterButton="Buscar"
          allowClear
          placeholder="Buscar por nombre de centro"
          onSearch={find}
        />
      </div>
      <div className="row justify-content-end mb-3 px-2">
        <Button
          type="primary"
          onClick={() => {
            setCenterToUpdate({ name: "", description: "" });
            setIsModalOpen(true);
            setIsUpdate(false);
          }}
          className="mx-1 w-auto"
        >
          <AppstoreAddOutlined />
          Agregar centro
        </Button>
      </div>
      <div className="row">
        <List
          loading={loading}
          size="small"
          bordered
          dataSource={data}
          renderItem={(item: CenterModel) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={item.name}
                description={item.description}
              />
              <div className="col-4 col-lg-1">
                <div className="row">
                  <Button
                    className="mb-2 "
                    onClick={() => {
                      setIsModalOpen(true);
                      setIsUpdate(true);
                      setCenterToUpdate(item);
                    }}
                    type="primary"
                  >
                    Editar
                  </Button>
                </div>
                <div className="row">
                  <Popconfirm
                    title={`Está seguro de eliminar el centro ${item.name}?`}
                    onConfirm={async () => {
                      await deleteHook.use(item.id!);
                      find();
                    }}
                    okText="Si"
                    cancelText="No"
                  >
                    <Button className="w-md-auto" type="primary" danger>
                      Eliminar
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </List.Item>
          )}
        />
        <EditCenter
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isUpdate={isUpdate}
          isLoadingUpdateCenter={isLoadingUpdateCenter}
          setIsLoadingUpdateCenter={setIsLoadingUpdateCenter}
          centerToUpdate={centerToUpdate}
          getAll={find}
        />
      </div>
    </div>
  ) : (
    <ErrorComponent />
  );
}

export default SearchCenters;
