import { message } from "antd";
import { BrandModel } from "../../model/Brands.model";
import { MediaBrandCheckModel, MediaModel, TicketModel } from "../../model/Ticket.model";
import { persistTicket } from "../../services/Ticket.service";
import { uploadFile } from "../../services/UploadFile.service";
import { Stuffs } from "../../vault/Stuffs";
import { WorkerResponse } from "../../vault/Task";
import { fromBase64ToFile } from "./UploadEvidenceService";

export const saveTicketWorkerCallBack = (
  stuffs?: Stuffs,
  response?: any | undefined,
  error?: boolean
) => {
  if (!error) {
    message.success(`Ticket creado sin conexión: ${(stuffs?.getStuff("ticket") as TicketModel).otName} creado correctamente.`);
  }
};

export const saveTicketWorker = async (stuffs: Stuffs) => {
  const ticket: TicketModel = stuffs.getStuff("ticket");

  if (ticket.mediaBrandCheck.medias) {
    for (let media of ticket.mediaBrandCheck.medias) {
      if (media.base64Content) {
        const file: File = fromBase64ToFile(media.base64Content, media.filename!)!;
        await uploadFile({
          file: file,
          filename: file.name,
        })
          .then((response: { filename: string }) => {
            media.mediaUrl = response.filename;
            media.base64Content = undefined;
            media.checked = true;
            media.loading = false;
            media.uploaded = true;
          });
      }
    }

    const isAllMediaOk: boolean = ticket.mediaBrandCheck.medias.find((media: MediaModel) => !media.checked && !media.uploaded) ? false : true;

    if (isAllMediaOk) {
      const response = await persistTicket(ticket)
        .catch((error: any) => {
          console.warn(
            "Error tratando de guardar ticket creado sin conexión. Se intentará de nuevo más tarde."
          );
          return false;
        });

      if (!response) {
        return WorkerResponse.withError("Error tratando de guardar ticket.");
      } else {
        return WorkerResponse.noError(response);
      }
    } else {
      stuffs.putStuff("ticket", ticket);
      return WorkerResponse.withError("Hay medias sin subir.");
    }

  } else {
    const selectedBrand = stuffs.getStuff<BrandModel>("selectedBrand");
    if (isMandatorySpacificationOk(ticket.mediaBrandCheck, selectedBrand)) {
      const response = await persistTicket(ticket)
        .catch((error: any) => {
          console.warn(
            "Error tratando de guardar ticket creado sin conexión. Se intentará de nuevo más tarde."
          );
          return false;
        });

      if (!response) {
        return WorkerResponse.withError("Error tratando de guardar ticket.");
      } else {
        return WorkerResponse.noError(response);
      }
    } else {
      stuffs.putStuff("ticket", ticket);
      return WorkerResponse.withError("Hay medias sin subir.");
    }
  }


};


export const isMandatorySpacificationOk = (mediaBrandCheck: MediaBrandCheckModel, selectedBrand: BrandModel) => {

  if (mediaBrandCheck) {
    let isAllMendatoryOk = true;

    if (selectedBrand) {
      console.log("SelectedBrand: OK ");
      for (let specification of selectedBrand?.specifications!) {
        console.log(
          "specification lenght: " + selectedBrand.specifications?.length
        );
        if (specification?.mandatory) {
          console.log("apec mandatory: " + JSON.stringify(specification));
          if (
            mediaBrandCheck?.medias &&
            mediaBrandCheck?.medias.length > 0
          ) {
            console.log(
              "Exists medias lenght: " + mediaBrandCheck?.medias.length
            );
            const mediaFromBrandSpec = mediaBrandCheck?.medias.find(
              (m) => m.brandSpecification === specification.name
            );

            if (mediaFromBrandSpec) {
              console.log("Media from brand spec: OK");
              if (!mediaFromBrandSpec.checked) {
                console.log("mediaBrandCheck.checked: OK");
                return false;
              }
            } else {
              console.log("Media from brand spec: NOK");
              return false;
            }
          } else {
            console.log("No medias");
            return false;
          }
        }
      }
    } else {
      return false;
    }

    if (isAllMendatoryOk) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}