import { AppstoreAddOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tag } from "antd";
import Search from "antd/lib/input/Search";
import Table, { ColumnType } from "antd/lib/table";
import React, { Fragment, useEffect } from "react";
import ErrorComponent from "../../components/error/ErrorComponent";
import { RoleModel } from "../../model/Role.model";
import { UserModel } from "../../model/User.model";
import { isUserAdmin } from "../../services/Auth.service";
import { GetHook } from "../../services/Base.service";
import ChangePassword from "./components/ChangePassword";
import EditUser from "./components/EditUser";
import UsersTable from "./components/UsersTable";
import { useGetRoles, useGetUsers } from "./User.service";

function AdminUsers() {
  const [searchInput, setSearchInput] = React.useState("");
  const [isEditUserOpen, setIsEditUserOpen] = React.useState(false);
  const [isEditUser, setIsEditUser] = React.useState<boolean>(false);
  const [userToUpdate, setUserToUpdate] = React.useState<UserModel>();
  const [isChangePasswordOpen, setIsChangePasswordOpen] =
    React.useState<boolean>(false);
  const rolesHook: GetHook = useGetRoles<RoleModel>();
  const getHook: GetHook = useGetUsers();

  useEffect(() => {
    search();
  }, [searchInput]);

  useEffect(() => {
    setData(getHook.data);
  }, [getHook.data]);

  const [data, setData] = React.useState<UserModel[]>([]);

  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  const getDataFromSearch = () => {
    const fullName = [
      ...getHook.data.filter((user: UserModel) =>
        user.fullName.toLowerCase().includes(searchInput.toLowerCase())
      ),
    ];

    const email = [
      ...getHook.data.filter((user: UserModel) =>
        user.email.toLowerCase().includes(searchInput.toLowerCase())
      ),
    ];

    const status = [
      ...getHook.data.filter((user: UserModel) =>
        user.status.toLowerCase().includes(searchInput.toLowerCase())
      ),
    ];

    const roles = [
      ...getHook.data.filter(
        (user: UserModel) =>
          user.roles.filter((role: string) =>
            role.toLowerCase().includes(searchInput.toLowerCase())
          ).length > 0
      ),
    ];

    return [...fullName, ...email, ...status, ...roles].filter(onlyUnique);
  };

  const search = (force: boolean = false) => {
    if (searchInput.trim().length === 0) {
      if (force) {
        getHook.reuse();
      } else {
        setData(getHook.data);
      }
    } else {
      setData([...getDataFromSearch()]);
    }
  };

  return isUserAdmin() ? (
    <Fragment>
      <div className="row">
        <Search
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          enterButton="Buscar"
          allowClear
          placeholder="Buscar por nombre de usuario, email o rol"
          onSearch={() => {
            search(true);
          }}
        />
      </div>
      <div className="row justify-content-end mt-3">
        <Button
          onClick={() => {
            setIsEditUser(false);
            setIsEditUserOpen(true);
            setUserToUpdate({
              password: "",
              fullName: "",
              email: "",
              roles: [],
              status: "ACTIVE",
              center: "",
              centers: []
            });
          }}
          className="w-auto mx-3"
          type="primary"
        >
          <AppstoreAddOutlined />
          Crear usuario
        </Button>
      </div>
      <div className="row mt-3">
        <UsersTable
          setIsEditUser={setIsEditUser}
          setIsEditUserOpen={setIsEditUserOpen}
          setUserToUpdate={setUserToUpdate}
          setIsChangePasswordOpen={setIsChangePasswordOpen}
          data={data}
          search={search}
          isLoading={getHook.loading}
        />
      </div>
      <EditUser
        isEdit={isEditUser}
        isModalOpen={isEditUserOpen}
        setIsModalOpen={setIsEditUserOpen}
        userToUpdate={{ ...userToUpdate! }}
        getAll={getHook.reuse}
        rolesHook={rolesHook}
      />
      <ChangePassword
        isModalOpen={isChangePasswordOpen}
        setIsModalOpen={setIsChangePasswordOpen}
        userToUpdate={userToUpdate!}
      />
    </Fragment>
  ) : (
    <ErrorComponent />
  );
}

export default AdminUsers;
