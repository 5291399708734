import { Modal } from "antd";
import { Fragment, useEffect, useState } from "react";

function MediaImage(props: {
  mediaUrl: string;
  name?: string;
  isImage?: boolean;
  isBase64?: boolean;
  maxHeight?: number;
  videoHeight?: number;
}) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [url, setUrl] = useState(
    props.isBase64
      ? props.mediaUrl
      : `${process.env.REACT_APP_UPLOAD_FILE_URL}/upload-file?filename=${props.mediaUrl}`
  );

  useEffect(() => {
    setUrl(
      props.isBase64
        ? props.mediaUrl
        : `${process.env.REACT_APP_UPLOAD_FILE_URL}/upload-file?filename=${props.mediaUrl}`
    );
  }, [props.mediaUrl]);

  const handleCancel = () => setPreviewVisible(false);

  return (
    <div className="row justify-content-center">
      <div className="col">
        {props.isImage ? (
          <div className="row justify-content-center m-3">
            <img
              style={{
                cursor: "pointer",
                maxHeight: props.maxHeight,
                maxWidth: "fit-content",
              }}
              onClick={() => setPreviewVisible(true)}
              src={url}
              alt={props.mediaUrl}
            />
          </div>
        ) : (
          <div className="row justify-content-center m-3">
            <video
              className="uploadedImage"
              style={{
                height: props.videoHeight,
                maxHeight: props.maxHeight,
                maxWidth: "fit-content",
              }}
              controls
            >
              <source
                src={`${process.env.REACT_APP_UPLOAD_FILE_URL}/upload-file?fileName=${props.mediaUrl}`}
                type="video/mp4"
              />
            </video>
          </div>
        )}
      </div>

      <Modal
        visible={previewVisible}
        title={props.name}
        footer={null}
        onCancel={handleCancel}
        style={{ top: 10 }}
      >
        {props.isImage ? (
          <img alt={props.name} style={{ width: "100%" }} src={url} />
        ) : (
          <video className="uploadedImage" controls>
            <source
              src={`${process.env.REACT_APP_UPLOAD_FILE_URL}/upload-file?fileName=${props.mediaUrl}`}
              type="video/mp4"
            />
          </video>
        )}
      </Modal>
    </div>
  );
}

export default MediaImage;
