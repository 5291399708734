import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { SearchTicketModel } from "../model/SearchTicketModel";
import { StateModel } from "../model/State.model";
import { TicketModel, TicketStateModel, TICKET_STATE } from "../model/Ticket.model";
import { getUser } from "./Auth.service";
import { get, post } from "./Base.service";


export const StatessContext = createContext<StateModel[]>([]);

export const searchTickets = (search: SearchTicketModel) => {
    const getMines = search.getMines || false;
    return post<TicketModel[]>(`${process.env.REACT_APP_TICKET_MANAGER_URL}/ticket/search?getMines=${getMines}`, search);
}

export const getAllTickets = (): Promise<TicketModel[]> => {
    return get(`${process.env.REACT_APP_TICKET_MANAGER_URL}/ticket`)
        .then((ts: any) => {
            return ts.map((t: any, i: number) => {
                t.key = t.id;
                t.readableDate = getReadableDateandHour(t.date);
                return t;
            })
        });
}

export const getTicketById = (id: string): Promise<TicketModel> => {
    return get(`${process.env.REACT_APP_TICKET_MANAGER_URL}/ticket/${id}`)
        .then((t: any) => {
            t.readableDate = getReadableDateandHour(t.date);
            asignKeysToStates(t);
            asignKeysToTicket(t);
            return t;
        });
}

const asignKeysToStates = (ticket: TicketModel) => {
    ticket.states.forEach((state: any, indexState: number) => {
        state.key = indexState + new Date().getTime();
        state.readableDate = getReadableDateandHour(state.stateDate);
    });
    return ticket;
}
const asignKeysToTicket = (ticket: any) => {
    ticket.key = ticket.id;
    return ticket;
}

const getReadableDateandHour = (dateStr: string) => {
    const date = new Date(dateStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}



export const updateTicketState = (stateTo: number,
    comments: string,
    role: string,
    ticketId: string,
    ocNumber?: string,
    evidence?: string,
    secondEvidence?: string
): Promise<TicketModel> => {
    return post<TicketModel>(`${process.env.REACT_APP_TICKET_MANAGER_URL}/ticket/state/${stateTo}/${role}?ticketId=${ticketId}`,
        {
            stateDescription: comments,
            ocNumber: ocNumber,
            evidence,
            secondEvidence
        });
}

export const goBackState = (actualState: number,
    comments: string,
    role: string,
    ticketId: string,
    ocNumber?: string,
    evidence?: string,
    secondEvidence?: string
): Promise<TicketModel> => {
    return post<TicketModel>
        (`${process.env.REACT_APP_TICKET_MANAGER_URL}/ticket/state/goback/${actualState}/${role}?ticketId=${ticketId}`,
            {
                stateDescription: comments,
                ocNumber: ocNumber,
                evidence,
                secondEvidence
            });
}

export const findState = (states: any, state: string): StateModel => {
    return states.find((s: any) => s === state);
}


export const getLastStateOrder = (ticket: TicketModel) => {
    return getLastState(ticket)?.stateOrder;
}

export const getLastState = (ticket: TicketModel): TicketStateModel | undefined => {
    if (ticket && ticket.states && ticket.states.length > 1) {
        ticket?.states.sort((a: TicketStateModel, b: TicketStateModel) => {
            return (
                new Date(a.stateDate).getTime() - new Date(b.stateDate).getTime()
            );
        });

        const lastState = ticket?.states[ticket?.states.length - 1];

        //setLastStateOrder(lastState?.stateOrder);
        return lastState;
    } else if (ticket && ticket.states && ticket.states.length === 1) {
        //setLastStateOrder(ticket?.states[0].stateOrder);
        return ticket?.states[0];
    }
};

export const getPreviousState = (ticket: TicketModel): Partial<TicketStateModel> | undefined => {
    if (ticket && ticket.states && ticket.states.length > 1) {
        ticket?.states.sort((a: TicketStateModel, b: TicketStateModel) => {
            return (
                new Date(a.stateDate).getTime() - new Date(b.stateDate).getTime()
            );
        });

        const lastState = ticket?.states[ticket?.states.length - 1];
        switch (lastState?.stateOrder) {
            case 1:
                return { stateOrder: 0, state: TICKET_STATE.EVALUACION };
            case 2:
                return { stateOrder: 0, state: TICKET_STATE.EVALUACION };
            case 3:
                return { stateOrder: 2, state: TICKET_STATE.SOLICITUD_RESPUESTO };
            case 4:
                return { stateOrder: 3, state: TICKET_STATE.REPUESTO_SOLICITADO };
            case 5:
                return { stateOrder: 4, state: TICKET_STATE.REPUESTO_RECEPCIONADO };
            default:
                return { stateOrder: -1, state: 'notFound' };
        }

        //setLastStateOrder(lastState?.stateOrder);
        // return lastState;
    }
};


export const getOcNumberFromCorrespondingState = (ticket: TicketModel): string | undefined => {
    return ticket.states.find((state: TicketStateModel) => {
        return state.state === TICKET_STATE.REPUESTO_SOLICITADO
    })?.ocNumber;
}

export function persistTicket(ticket: TicketModel): Promise<TicketModel> {
    return post(`${process.env.REACT_APP_TICKET_MANAGER_URL}/ticket`, ticket)
        .then((result: any) => {
            return result;
        });
}


export function useStates() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [states, setStates] = useState<any>([]);
    const user = getUser();

    const reuse = () => {
        setLoading(true);
        const fetchData = () => {
            if (user) {
                try {
                    axios(`${process.env.REACT_APP_TICKET_MANAGER_URL}/ticket/state`)
                        .then((result: any) => {
                            setStates(result.data);
                            setLoading(false);
                        })
                } catch (error) {
                    setError(true);
                }
            } else {
                setLoading(false);
                setError(false);
            }
        };
        fetchData();
    };

    useEffect(reuse, []);

    return { loading, error, reuse, states };

}
function Dispatch<T>(): any {
    throw new Error("Function not implemented.");
}

