import { Button, Space, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EditButton from "../../components/editbutton/EditButton";
import TicketStateTag from "../../components/state-tag/TicketStateTag";
import SearchTicketFilters from "../../components/ticket-table/SearchTicketFilters";
import TicketTable from "../../components/ticket-table/TicketTable";
import { SearchTicketModel } from "../../model/SearchTicketModel";
import { TicketModel, TICKET_STATE } from "../../model/Ticket.model";
import { canEditTicket } from "../../services/Auth.service";
import { getLastState, searchTickets } from "../../services/Ticket.service";
import {
  SearchOutlined,
} from "@ant-design/icons";
import "./SearchTickets.css";

function SearchTickets() {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<TicketModel[]>([]);
  const [searchModel, setSearchModel] = useState<SearchTicketModel>({
    getMines: true,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    search();
  }, []);

  const search = () => {
    setLoading(true);
    searchTickets(searchModel).then((tickets) => {
      setTickets(tickets);
      setLoading(false);
    });
  };

  return (
    <div className="col">
      <div className="row justify-content-center">
        <SearchTicketFilters
          searchModel={searchModel}
          setSearchModel={setSearchModel}
          showGetMines={true}
        />
      </div>
      <div className="row mt-3 mb-3 justify-content-end w-100">
        <Button disabled={loading}
         type="primary" onClick={search} className="w-auto">
          {!loading && <SearchOutlined />}
          {loading && <span className="loader-xs"></span>}
          Buscar
        </Button>
      </div>

      <TicketTable tickets={tickets}></TicketTable>

      <div className="row"></div>
    </div>
  );
}

export default SearchTickets;
