import { Box } from "./Box";
import { TaskCallBack, TaskWorker } from "./Task";
import { VaultLogger } from "./VaultLogger";
import { VaultStorage } from "./VaultStorage";
import { generateSlug } from "random-word-slugs";
import { WorkerDetail } from "./WorkerDetail";

export class Vault {

    private timer!: NodeJS.Timer;
    public secondsToRunTasks: number = 5900;
    public static deleteEmptyBoxes: boolean = true;
    public static VAULT_STORAGE_NAME = "Vault";
    private static instance: Vault;
    public boxes: Box[] = [];
    private workers: Map<string, TaskWorker> = new Map();
    private workersCount: number = 0;
    private workerDetails: WorkerDetail[] = []
    private callBacks: Map<string, TaskCallBack> = new Map();

    private constructor() {
        this.recoverTasks();
    }

    public static getInstance(): Vault {
        if (!Vault.instance) {
            Vault.instance = new Vault();
        }
        //Vault.instance.asignWorkersToTasks();
        return Vault.instance;
    }

    public static runTasks() {
        return new Promise<void>((resolve, reject) => {
            VaultLogger.logs("Running tasks...");
            const tasksToRun: Box[] = [];
            Vault.getInstance().boxes.forEach(box => {
                if (box.tasks.length > 0) {
                    tasksToRun.push(box);
                }
            });

            if (tasksToRun.length > 0) {
                VaultLogger.logs("Found " + tasksToRun.length + " tasks to run");
                tasksToRun.forEach(async box => {
                    await box.runTasks();
                });
            } else {
                VaultLogger.logs("Nothing to run");
            }
            resolve();
        });
    }

    public removeEmptyBoxes(){
        this.boxes = this.boxes.filter(box => box.tasks.length !== 0);
        VaultStorage.update();
    }

    public removeBox(box: Box): Box[]{
        this.boxes = this.boxes.filter(b => b.name !== box.name);
        VaultStorage.update();
        return this.boxes;
    }

    public asignWorkersToTasks() {
        this.workers.forEach((value: TaskWorker, workerName: string) => {
            //const boxName = boxTaskName.split(";")[0];
            const taskName = workerName.split(";")[1];

            // if (boxName.trim().length === 0) {

            const boxesWithTaskName = Vault.getInstance().boxes.filter(box => box.hasTask(taskName));
            boxesWithTaskName.forEach(box => {
                Vault.getBox(box.name).getTask(taskName)?.withWorker(value);
            });


            // } else {
            //     Vault.getBox(boxName).getTask(taskName)?.withWorker(value);
            //     const callBack: TaskCallBack | undefined = this.callBacks.get(boxTaskName);
            //     if (callBack) {
            //         Vault.getBox(boxName).getTask(taskName)?.withCallBack(callBack);
            //     }
            // }


        });
    }

    public static addWorkerForTask(taskName: string, worker: TaskWorker, callBack?: TaskCallBack): Vault {
        //const boxesWithTaskName = Vault.getInstance().boxes.filter(box => box.hasTask(taskName));

        // boxesWithTaskName.forEach(box => {
        const workerName = ";" + taskName
        Vault.getInstance().workers.set(workerName, worker);
        Vault.getInstance().workersCount++;
        Vault.getInstance().workerDetails.push({
            workerName: workerName,
            taskName: taskName,
            functionNameWorker: worker.name,
            functionNameCallBack: callBack?.name || "N/A"
        });

        if (callBack) {
            Vault.getInstance().callBacks.set(workerName, callBack);
        }
        // });

        Vault.getInstance().asignWorkersToTasks();
        VaultStorage.update();
        return Vault.getInstance();
    }

    public static init(deleteEmptyBoxes: boolean = false): Vault {
        VaultLogger.logs("Initializing Vault task recovery");
        Vault.deleteEmptyBoxes = deleteEmptyBoxes;
        const vault: Vault = Vault.getInstance();

        VaultLogger.logs("Running init pipeline. I'm almost ready...");
        vault.setIntervalTasks();

        return vault;
    }

    private recoverTasks() {
        VaultLogger.logs("Recovering tasks...");

        const vaultParsed: Vault | null = VaultStorage.get();

        if (vaultParsed) {
            VaultLogger.logs("Vault recovered!!!!");

            if (vaultParsed.boxes && vaultParsed.boxes.length > 0) {
                this.boxes = vaultParsed.boxes.map(box => Box.fromName(box.name).withStuffs(box.stuffs).addJsonTasks(box.tasks));
            } else {
                this.boxes = [];
                VaultLogger.logs("Nothing to do in te Vault. Im keep waiting for some to do...");
            }

        } else {
            VaultLogger.logs("No vault in localstorage, creating for the first time...");
            VaultStorage.set(this);
        }
    }


    public setIntervalTime(seconds: number) {
        this.secondsToRunTasks = seconds;
        return this;
    }


    public setIntervalTasks(secondsToRunTasks?: number) {
        VaultLogger.logs("Setting the interval module to run tasks every " + this.secondsToRunTasks + " seconds");
        const timer: NodeJS.Timer = setInterval(async () => {
            VaultLogger.logs('Tryigin to run all queued tasks');
            await this.runTasks();
            this.removeEmptyBoxes();
        }, secondsToRunTasks || this.secondsToRunTasks * 1000);

        this.timer = timer;
    }

    public static getBox(boxName: string): Box {
        let box: Box | undefined = Vault.getInstance().boxes.find(box => box.name === boxName);

        if (!box) {
            box = Vault.addBox(boxName);

        }

        return box;
    }

    private static addBox(boxName: string): Box {
        VaultLogger.logs("Adding new box: " + boxName);
        const box: Box = new Box(boxName);
        Vault.getInstance().boxes.push(box);
        return box;
    }

    public static removeBox(boxName: string) {
        const box: Box | undefined = Vault.getInstance().boxes.find(box => box.name === boxName);

        if (box) {
            Vault.getInstance().boxes = Vault.getInstance().boxes.filter(box => box.name !== boxName);
        }
    }

    private async runTasks() {
        if (this.boxes && this.boxes.length > 0) {
            this.boxes.forEach(box => {
                box.runTasks();
            });
        } else {
            VaultLogger.logs("No boxes in Vault. Im keep waiting for do something...");
        }
    }

    public static generateVaultSessionId(): string {
        return "vault-" + generateSlug();
    }

    public reset() {
        this.clearTimer();
        VaultStorage.clear();
        const instance: Vault = new Vault();
        instance.secondsToRunTasks = this.secondsToRunTasks;
        Vault.instance = instance;
    }

    public clearTimer() {
        clearInterval(this.timer);
    }
}
