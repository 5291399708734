import { Button, message, Modal } from "antd";
import { TicketModel } from "../../../model/Ticket.model";
import { Box } from "../../../vault/Box";
import { ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import "./VaultCard.css";
import { Vault } from "../../../vault/Vault";
import { useState } from "react";
import ViewTicketNoconnection from "./view-ticket/ViewTicket";

function VaultCard(props: { box: Box; setBoxes: (boxes: Box[]) => void }) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState<boolean>(false);
  const [ticketToView, setTicketToView] = useState<TicketModel>(
    {} as TicketModel
  );

  return props.box.tasks.length === 0 ? null : (
    <div className="row card-wraper">
      <div className="col-9">
        <div className="row title p-1">Sesión:</div>
        <div className="row info">
          {props.box.name} -{" "}
          {new Date(props.box.stuffs.getStuff<Date>("date")).toLocaleString()}
        </div>
        <div className="row title p-1">Tarea:</div>
        <div className="row info">
          {props.box.tasks.map((task) => task.name)}
        </div>
        <div className="row title p-1">Ticket:</div>
        <div className="row info">
          {props.box.stuffs.getStuff<TicketModel>("ticket").otName} -
          <a
            href="#!"
            className="w-auto"
            onClick={() => {
              setTicketToView(props.box.stuffs.getStuff<TicketModel>("ticket"));
              setIsModalOpen(true);
            }}
            type="link"
          >
            {" "}
            Visualizar
          </a>
        </div>
      </div>
      <div className="col">
        <div className="row align-items-end h-100">
          <div className="col">
            <div className="row justify-content-end">
              <Button
                disabled={props.box.loading}
                className="removeButton w-auto"
                type="primary"
                shape="circle"
                onClick={() => {
                  console.log("Holi");
                  setTicketToView(
                    props.box.stuffs.getStuff<TicketModel>("ticket")
                  );
                  setIsModalRemoveOpen(true);
                  // props.setBoxes([...Vault.getInstance().removeBox(props.box)]);
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
            <div className="row justify-content-end">
              <Button
                disabled={props.box.loading}
                onClick={async () => {
                  props.box.loading = true;
                  props.setBoxes([...Vault.getInstance().boxes]);
                  message.info("Ejecutando tarea: " + props.box.tasks[0].name);
                  Vault.getBox(props.box.name)
                    .runTasks()
                    .then(() => {
                      const boxes = Vault.getInstance().boxes;
                      props.setBoxes([...boxes]);
                      Vault.getInstance().removeEmptyBoxes();
                    })
                    .catch((error) => {
                      message.error(
                        "Ha ocurrido un error al intentar ejecutar esta tarea. Se intentará automáticamente más tarde."
                      );
                      const boxes = Vault.getInstance().boxes;
                      props.setBoxes([...boxes]);
                      Vault.getInstance().removeEmptyBoxes();
                    });
                }}
                className="reloadButton w-auto"
                type="primary"
                shape="circle"
              >
                <ReloadOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        width="100%"
        style={{ top: "10px" }}
        title={`Visualizar ticket`}
        visible={isModalOpen}
        confirmLoading={false}
        footer={[
          <Button type="primary" onClick={() => setIsModalOpen(false)}>
            Aceptar
          </Button>,
        ]}
      >
        <ViewTicketNoconnection ticket={ticketToView} />
      </Modal>
      <Modal
        title={`Atención!`}
        visible={isModalRemoveOpen}
        confirmLoading={false}
        footer={[
          <Button
            type="default"
            onClick={() => {
              setIsModalRemoveOpen(false);
            }}
          >
            Cancelar
          </Button>,
          <Button
            type="primary"
            danger
            onClick={() => {
              props.setBoxes([...Vault.getInstance().removeBox(props.box)]);
              setIsModalRemoveOpen(false);
            }}
          >
            Aceptar
          </Button>,
        ]}
        onCancel={() => {
          setIsModalRemoveOpen(false);
        }}
      >
        <div>Está seguro de eliminar el ticket {ticketToView.otName}?</div>
      </Modal>
    </div>
  );
}

export default VaultCard;
