import { Badge } from "antd";

function BadgeStatus(props: {
    type: string
    text: string
    style?: any
}) {
    return ( 
        <Badge className={props.type} color={props.type} text={props.text} style={props.style} />
     );
}

export default BadgeStatus;