import { MediaBrandCheckModel, MediaModel } from "../../model/Ticket.model";
import { isImageByType } from "../../pages/create-ticket/UploadEvidenceService";
import MediaImage from "../media-img/MediaImg";
import "./MediaList.css";

function MediaList(props: { brandcheck: MediaBrandCheckModel }) {
  if (props.brandcheck && props.brandcheck?.medias) {
    return (
      <div className="row w-100">
        {props.brandcheck?.medias.map((img: MediaModel) => {
          return !img.mediaUrl && img.base64Content ? (
            <div className="col-12 col-lg" key={img.base64Content}>
              <MediaImage
                maxHeight={200}
                mediaUrl={img.base64Content}
                isBase64
                isImage={isImageByType(img.mediaType)}
              />
            </div>
          ) : (
            <div className="col-12 col-lg" key={img.mediaUrl}>
              <MediaImage
                videoHeight={200}
                maxHeight={200}
                mediaUrl={img.mediaUrl}
                isImage={isImageByType(img.mediaType)}
              />
            </div>
          );
        })}
      </div>
    );
  }
  return <div className="row mx-4">No hay evidencias para este ticket.</div>;
}

export default MediaList;
