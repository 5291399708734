import axios from "axios";
import { getToken, getUser, refreshToken } from "./Auth.service";
import * as reactRouterDom from 'react-router-dom'
import { NavigateFunction } from "react-router-dom";

export function setupInterceptors(signOut: any) {
  axios.interceptors.request.use(function (config) {
    const token: string | null = getToken();
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });


  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    console.log("response status: ", response.status);

    return response;
  }, function (error) {
    const errorStatus = error.response?.status;
    console.log("Error en alguna parte de la respuesta: " + errorStatus);
    if (errorStatus === 401) {
      const user = getUser();
      if(user){
        signOut();
      }
    }

    return Promise.reject(error);
  });  //"Backen for frontend"
}