import { LoadingOutlined } from "@ant-design/icons";
import { Descriptions, message, Tag, Typography } from "antd";
import Table, { ColumnType } from "antd/lib/table";
import { throws } from "assert";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditButton from "../../components/editbutton/EditButton";
import MediaImage from "../../components/media-img/MediaImg";
import MediaList from "../../components/media-list/MediaList";
import TicketStateTag from "../../components/state-tag/TicketStateTag";
import { TicketModel, TicketStateModel } from "../../model/Ticket.model";
import { UserModel } from "../../model/User.model";
import { getUser } from "../../services/Auth.service";
import { getBrandById as getBrandNameById } from "../../services/Brand.service";
import { RolesContext } from "../../services/Roles.service";
import {
  getLastStateOrder,
  getPreviousState,
  getTicketById,
  goBackState,
  updateTicketState,
} from "../../services/Ticket.service";
import ConfirmUpdateState from "./components/ConfirmUpdateState";
import UpdateTicketButton from "./components/UpdateTicketButton";
import "./ViewTicket.css";
import GoBackStateButton from "./components/GoBackStateButton";
import { log } from "console";
import { last } from "rxjs";

const { Text } = Typography;

function ViewTicket() {
  const [ticket, setTicket] = useState<TicketModel>();
  const { id } = useParams();
  const [user, setUser] = useState<UserModel | null>(null);
  const [lastStateOrder, setLastStateOrder] = useState<number>(0);
  const roles = useContext(RolesContext);
  const [isConfirmVisible, setIsConfirmVisible] = useState<boolean>(false);
  const [confirmationCallBack, setConfirmationCallBack] = useState<Function>(
    () => { }
  );
  const [newState, setNewState] = useState<string>("");
  const [brandName, setBrandName] = useState<string | null>(null);
  const [isStateImgRequired, setIsStateImgRequired] = useState<boolean>(false);
  const [loadingStateChange, setLoadingStateChange] = useState<boolean>(false);
  const [isGoBack, setIsGoBack] = useState<boolean>(false);
  const [previousState, setPreviousState] = useState<Partial<TicketStateModel> | null>(null);

  const searchTicket = async (id: string): Promise<TicketModel> => {
    return await getTicketById(id).then((ticket: any) => {
      setTicket({ ...ticket });
      const _lastStateOrder = getLastStateOrder(ticket)!;
      console.log("lastStateOrder", _lastStateOrder);
      setLastStateOrder(_lastStateOrder!);

      let _previousState = getPreviousState(ticket);
      let _previousStateOrder = _previousState?.stateOrder || 0;


      if (_lastStateOrder === 1) {
        _previousStateOrder = _lastStateOrder - 1;
      }

      console.log("previousStateOrder", _previousStateOrder);

      //const _previousState = ticket.states[_previousStateOrder];
      console.log("ticket.states", ticket.states);
      console.log("previousState", _previousState);

      setPreviousState(_previousState!);



      return ticket;
    });
  };

  useEffect(() => {
    const user: UserModel | null = getUser();
    if (user) {
      setUser({ ...user });
    }
  }, []);

  useEffect(() => {
    if (id) {
      searchTicket(id).then((ticket: TicketModel) => {
        console.log("ticket", ticket);
        getBrandNameById(ticket.mediaBrandCheck.brandId).then(
          (brandName: string) => {
            setBrandName(brandName);
          }
        );
      });
    }
  }, [id]);

  function updateState(
    stateTo: number,
    role: string,
    comments?: string,
    ocNumber?: string,
    evidence?: string,
    secondEvidence?: string,
    isGoBack: boolean = false
  ) {
    comments = comments || "";
    setLoadingStateChange(true);
    let updateTicketPromise = updateTicketState;
    if (isGoBack) {
      updateTicketPromise = goBackState;
      console.log("Is goback, using goBackStateToAPI instead change state.");
    }
    updateTicketPromise(
      stateTo,
      comments,
      role,
      ticket!.id,
      ocNumber,
      evidence,
      secondEvidence
    ).then(() => {
      //setTicket({ ...ticket });
      searchTicket(id!);
      window.location.reload();
      message.info("Ticket actualizado correctamente.");
    })
      .finally(() => {
        setLoadingStateChange(false);
        if (isGoBack) {
          window.location.reload();
          //searchTicket(id!);
        }
      });
  }

  const isUserInRole = (rol: string): boolean => {
    if (user) {
      return user.roles.includes(rol);
    }
    return true;
  };

  const showButton = (state: number, role: string): boolean => {
    const isRole: boolean = isUserInRole(role);

    if (isRole) {
      if (state === lastStateOrder) {
        return true;
      }
    }

    return false;
  };

  const getColorFromRole = (role: string): string => {
    if (roles) {
      const roleObj: any = roles.find((r: any) => r.role === role);
      if (roleObj) {
        return roleObj?.color;
      }
    }

    return "green";
  };

  const columns: ColumnType<TicketStateModel>[] = [
    {
      title: "Detalle",
      dataIndex: "state",
      key: "state",
      render: (text: string, record: TicketStateModel) => {
        return (
          <div>
            <TicketStateTag text={text} state={record.state} />
            <br />
            <div className="mb-1">{record.readableDate}</div>
            <div className="mb-1">
              {record.fullName} /{" "}
              <a href={"mailto:" + record.userEmail}>{record.userEmail}</a>
            </div>
            {!record.isGoBack &&
              <Tag color={getColorFromRole(record.role)}>{record.role}</Tag>
            }
            {record.isGoBack &&
              <Tag color={'red'}>Ticket devuelto</Tag>
            }
            <br />
          </div>
        );
      },
    },
    {
      title: "Descripción",
      dataIndex: "stateDescription",
      key: "stateDescription",
      render: (text: string, record: TicketStateModel) => {
        return (
          <Fragment>
            <p>{text ? text : "Sin comentarios"}</p>
            {record.ocNumber ? (
              <p>
                Número de pedido: <Text code>{record.ocNumber}</Text>
              </p>
            ) : null}
            {record.evidence ? (
              <>
                Evidencias:
                <div style={{ maxWidth: 300, border: 'solid 1px #cfc7c7', borderRadius: '8px' }}>
                  <MediaImage mediaUrl={record.evidence} isImage={true} />
                </div>
                <div style={{ maxWidth: 300, border: 'solid 1px #cfc7c7', borderRadius: '8px', marginTop: '5px' }}>
                  <MediaImage mediaUrl={record.secondEvidence} isImage={true} />
                </div>
              </>
            ) : null}
          </Fragment>
        );
      },
    },
  ];

  const getRoleFromChangeState = () => {
    if (isUserInRole("EncargadoGarantia")) {
      return "EncargadoGarantia";
    }
    if (isUserInRole("AsesorServicios")) {
      return "AsesorServicios";
    }

    return null;
  };

  return (
    <div className="col">
      <div className="row">
        <Descriptions layout="vertical" title="Ticket Info:" bordered>
          <Descriptions.Item label="Correlativo">
            {ticket?.correlative}
          </Descriptions.Item>
          <Descriptions.Item label="Número de OT">
            {ticket?.otName}
          </Descriptions.Item>
          <Descriptions.Item label="Centro">
            {ticket?.centerName}
          </Descriptions.Item>
          <Descriptions.Item label="Marca">
            {brandName ? (
              brandName
            ) : (
              <div>
                <LoadingOutlined /> Cargando marca...
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Detalle de la falla">
            {ticket?.failDetail}
          </Descriptions.Item>
          <Descriptions.Item label="Código de repuesto">
            {ticket?.sparePartNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Detalle del repuesto">
            {ticket?.replacementRequest}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="row">
        <b className="mt-4 mb-3">Evidencias:</b>
        <MediaList brandcheck={ticket?.mediaBrandCheck!} />
      </div>
      <div className="row">
        <b className="mt-3 mb-3">Estados:</b>
        <Table
          pagination={false}
          size="small"
          columns={columns}
          dataSource={ticket?.states}
        />
      </div>
      <div className="row justify-content-end align-items-center mt-3 mx-1">

        {lastStateOrder !== 0 && lastStateOrder !== 6 &&
          <div className="w-auto pt-3 mx-2">
            <GoBackStateButton
              onClick={() => {
                setIsConfirmVisible(true);
                const _newState = previousState?.state!
                  .split("_")
                  .join(" ")
                  .toLocaleLowerCase();
                setNewState(_newState || "");
                setIsGoBack(true);
                setConfirmationCallBack(
                  () => (comments?: string, ocNumber?: string) => {
                    if (!comments) {
                      comments = "Se devuelve ticket sin comentarios."
                    } else {
                      comments = "Se devuelve ticket. Comantarios: " + comments
                    }
                    updateState(previousState!.stateOrder!, "EncargadoGarantia", comments, ocNumber,
                      undefined, undefined, true);
                    console.log("comments", comments);
                  }

                );
              }}
            />
          </div>
        }

        {showButton(0, "EncargadoGarantia") ? (
          <UpdateTicketButton
            type="magenta"
            onClick={() => {
              setIsConfirmVisible(true);
              setNewState("Solicitar repuestos");
              setIsGoBack(false);
              setConfirmationCallBack(
                () => (comments?: string, ocNumber?: string) =>
                  updateState(2, "EncargadoGarantia", comments, ocNumber)
              );
            }}
            text="Solicitar repuesto"
          />
        ) : null}

        {showButton(0, "EncargadoGarantia") ? (
          <div className="w-auto">
            <UpdateTicketButton
              onClick={() => {
                setIsConfirmVisible(true);
                setNewState("Rechazado");
                setIsGoBack(false);
                setConfirmationCallBack(
                  () => (comments?: string, ocNumber?: string) =>
                    updateState(1, "EncargadoGarantia", comments, ocNumber)
                );
              }}
              type="volcano"
              text="Rechazar"
            />
          </div>
        ) : null}

        {showButton(2, "AnalistaRepuesto") ? (
          <UpdateTicketButton
            className="w-auto"
            type="green"
            onClick={() => {
              setConfirmationCallBack(
                () => (comments?: string, ocNumber?: string) => {
                  updateState(3, "AnalistaRepuesto", comments, ocNumber);
                }
              );
              setIsGoBack(false);
              setIsConfirmVisible(true);
              setNewState("Repuesto solicitado");
            }}
            text="Repuesto solicitado"
          />
        ) : null}

        {showButton(3, "AsistenteBodega") ? (
          <UpdateTicketButton
            type="cyan"
            onClick={() => {
              setIsConfirmVisible(true);
              setNewState("Repuesto recepcionado");
              setIsGoBack(false);
              setConfirmationCallBack(
                () => (comments?: string, ocNumber?: string) =>
                  updateState(4, "AsistenteBodega", comments, ocNumber)
              );
            }}
            text="Repuesto recepcionado"
          />
        ) : null}
        {showButton(4, "AsesorServicios") ? (
          <UpdateTicketButton
            color="#a0d911"
            type="lime"
            onClick={() => {
              setIsConfirmVisible(true);
              setNewState("Respuesto instalado");
              setIsStateImgRequired(true);
              setIsGoBack(false);
              setConfirmationCallBack(
                () =>
                  (comments?: string, ocNumber?: string, evidence?: string, secondEvidence?: string) => {
                    updateState(
                      5,
                      "AsesorServicios",
                      comments,
                      ocNumber,
                      evidence,
                      secondEvidence
                    );
                    console.log("urlEvidence", evidence);
                    console.log("urlsecondEvidence", secondEvidence);
                  }
              );
            }}
            text="Repuesto instalado"
          />
        ) : null}
        {showButton(5, "EncargadoGarantia") ? (
          <UpdateTicketButton
            type="gold"
            onClick={() => {
              setIsConfirmVisible(true);
              setNewState("Finalizado");
              setIsGoBack(false);
              setConfirmationCallBack(
                () => (comments?: string, ocNumber?: string) =>
                  updateState(6, "EncargadoGarantia", comments, ocNumber)
              );
            }}
            text="Finalizar"
          />
        ) : null}
        {ticket ? (
          <div className="mt-3 w-auto h-auto">
            <EditButton ticket={ticket} />
          </div>
        ) : null}
        <Link className="w-auto h-100 mt-3" to="/search">
          {" "}
          {`< Atrás`}
        </Link>
      </div>
      <ConfirmUpdateState
        visible={isConfirmVisible}
        setVisible={setIsConfirmVisible}
        title="Confirmar cambio de estado"
        isGoBack={isGoBack}
        callBack={confirmationCallBack}
        withOcNumber={lastStateOrder === 2}
        isStateImgRequired={isStateImgRequired}
        newState={newState}
      />
      {loadingStateChange && <div className="loading-wrapper" >
        <div className="loading-content">
          <div style={{
            display: "flex",
            gap: "15px",
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <span className="loader"></span>
            procesando
          </div>
        </div>
      </div>}
    </div>
  );
}

export default ViewTicket;
