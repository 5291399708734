import { Badge } from "antd";
import { useContext } from "react";
import { StatessContext } from "../../services/Ticket.service";

function StateBadgeColor(props: {state: string}) {
    const states = useContext(StatessContext);

    const getColorFromState = (state: string): string => {
        // get state from states
        const stateFromStates: any = states.find((s: any) => s.state === state);
        if (stateFromStates) {
          return stateFromStates.color;
        }

        return "red";
      };

    return ( 
        <Badge color={getColorFromState(props.state)} />
     );
}

export default StateBadgeColor;