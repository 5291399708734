import { Descriptions, Select, Tag, Typography } from "antd";
import Table, { ColumnType } from "antd/lib/table";
import { Fragment, useContext, useState } from "react";
import TicketStateTag from "../../../../components/state-tag/TicketStateTag";
import { TicketModel, TicketStateModel } from "../../../../model/Ticket.model";
import { UserModel } from "../../../../model/User.model";
import { RolesContext } from "../../../../services/Roles.service";
import MediaList from "../../../../components/media-list/MediaList";
import "./ViewTicket.css";

const { Text } = Typography;
const { Option } = Select;

function ViewTicketNoconnection(props: { ticket: TicketModel }) {
  const [user, setUser] = useState<UserModel | null>(null);
  const [lastStateOrder, setLastStateOrder] = useState<number>(0);
  const roles = useContext(RolesContext);
  const [newStateDescription, setNewStateDescription] = useState<string>("");
  const [isConfirmVisible, setIsConfirmVisible] = useState<boolean>(false);
  const [confirmationCallBack, setConfirmationCallBack] = useState<Function>(
    () => {}
  );
  const [newState, setNewState] = useState<string>("");

  function handleChangeState(value: string) {
    // ticket!.state = value;
    // setTicket(ticket);
  }

  const isUserInRole = (rol: string): boolean => {
    if (user) {
      return user.roles.includes(rol);
    }
    return true;
  };

  const showButton = (state: number, role: string): boolean => {
    const isRole: boolean = isUserInRole(role);

    if (isRole) {
      if (state === lastStateOrder) {
        return true;
      }
    }

    return false;
  };

  const getColorFromRole = (role: string): string => {
    if (roles) {
      const roleObj: any = roles.find((r: any) => r.role === role);
      if (roleObj) {
        return roleObj?.color;
      }
    }

    return "green";
  };

  const columns: ColumnType<TicketStateModel>[] = [
    {
      title: "Detalle",
      dataIndex: "state",
      key: "state",
      render: (text: string, record: TicketStateModel) => {
        return (
          <div>
            <TicketStateTag text={text} state={record.state} />
            <br />
            <div className="mb-1">{record.readableDate}</div>
            <div className="mb-1">
              {record.fullName} /{" "}
              <a href={"mailto:" + record.userEmail}>{record.userEmail}</a>
            </div>
            <Tag color={getColorFromRole(record.role)}>{record.role}</Tag>
            <br />
          </div>
        );
      },
    },
    {
      title: "Descripción",
      dataIndex: "stateDescription",
      key: "stateDescription",
      render: (text: string, record: TicketStateModel) => {
        return (
          <Fragment>
            <p>{text ? text : "Sin comentarios"}</p>
            {record.ocNumber ? (
              <p>
                Número de pedido: <Text code>{record.ocNumber}</Text>
              </p>
            ) : null}
          </Fragment>
        );
      },
    },
  ];

  return (
    <div className="col">
      <div className="row">
        <Descriptions layout="vertical" title="Ticket Info:" bordered>
          <Descriptions.Item label="Número de OT">
            {props.ticket?.otName}
          </Descriptions.Item>
          <Descriptions.Item label="Centro">
            {props.ticket?.centerName}
          </Descriptions.Item>
          <Descriptions.Item label="Detalle de la falla">
            {props.ticket?.failDetail}
          </Descriptions.Item>
          <Descriptions.Item label="Repuestos solicitados">
            {props.ticket?.replacementRequest}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="row">
        <b className="mt-4 mb-3">Evidencias:</b>
        {console.log("hhhhhhhhhhhhhhhhhhhhh")}

        {console.log(props.ticket.states)}
        <MediaList brandcheck={props.ticket?.mediaBrandCheck!} />
      </div>
      {props.ticket.states ? <div className="row">
        <b className="mt-3 mb-3">Estados:</b>
        <Table
          pagination={false}
          size="small"
          columns={columns}
          dataSource={props.ticket?.states}
        />
      </div> : <div className="mt-3"> El ticket es nuevo y aún no tiene estados.</div>}
    </div>
  );
}

export default ViewTicketNoconnection;
