import { Form, Input, message, Modal } from "antd";
import { useEffect } from "react";
import { CenterModel } from "../../model/CenterModel";
import {
  PostHook,
  PutHook,
  usePost,
  usePut,
} from "../../services/Base.service";

function EditCenter(props: any) {
  const [form] = Form.useForm();
  const putHook: PutHook = usePut<CenterModel>("center");
  const postHook: PostHook = usePost<CenterModel>("center");

  useEffect(() => {
    form.resetFields();
  }, [props.centerToUpdate]);
  useEffect(() => {
    form.resetFields();
  }, [props.isModalOpen]);

  const updateOrCreateCenter = (values: any) => {
    if (props.isUpdate) {
      props.centerToUpdate.name = values.name;
      props.centerToUpdate.description = values.description;

      putHook.use(props.centerToUpdate).then((center: CenterModel) => {
        props.getAll();
        props.setIsModalOpen(false);
        props.setIsLoadingUpdateCenter(false);
        message.success("Centro actualizado");
      });
    } else {
      const center: CenterModel = {
        name: values.name,
        description: values.description,
      };

      postHook.use(center).then((_: any) => {
        props.setIsModalOpen(false);
        props.getAll();
        message.success("Centro creado");
      });
    }
  };

  return (
    <Modal
      width="100%"
      title={`${props.isUpdate ? "Editar" : "Crear"} Centro ${
        props.isUpdate ? props.centerToUpdate?.name : ""
      }`}
      visible={props.isModalOpen}
      confirmLoading={postHook.loading || putHook.loading}
      onOk={async () => {
        props.setIsLoadingUpdateCenter(true);
        form.submit();
      }}
      onCancel={() => {
        props.setIsModalOpen(false);
        form.resetFields();
      }}
    >
      <Form
        onFinish={(values) => {
          updateOrCreateCenter(values);
        }}
        layout="vertical"
        name="basic"
        form={form}
      >
        <Form.Item
          label="Nombre del centro"
          initialValue={props.centerToUpdate?.name}
          name="name"
          rules={[{ required: true, message: "El campo es obligatorio!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción del centro"
          name="description"
          initialValue={props.centerToUpdate?.description}
          rules={[{ required: true, message: "El campo es obligatorio!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditCenter;
