import { Button, Form, Input, message } from "antd";
import { Buffer } from "buffer";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { singIn } from "../../services/Auth.service";
import "./Signin.css";
import "../../fonts/Roboto-Light.ttf";
import { ArrowRightOutlined } from "@ant-design/icons";
import useWindowSize from "../../hooks/WindowSizeHook";

export default function Signin() {
  const [loading, setLoading] = useState<boolean>(false);
  //const { clockString, hour, minute, second } = useClock();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [reCapthcaToken, setReCapthcaToken] = useState<string | undefined>(
    undefined
  );
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 581);
  }, [size.width]);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async (): Promise<
    string | undefined
  > => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return undefined;
    }

    const token = await executeRecaptcha("Authetincation");

    setReCapthcaToken(token);
    return token;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const signin = async (values: any) => {
    //console.log("Success:", values);
    let token: string | undefined = reCapthcaToken;
    if (!reCapthcaToken) {
      token = await handleReCaptchaVerify();
    }

    if (token) {
      setLoading(true);
      const pass: string = Buffer.from(values.password).toString("base64");
      singIn(values.email, pass)
        .then(() => {
          window.location.href = "/";
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error en signin opt");
        });
    } else {
      console.error("Cant get reCaptcha token... HA-HA!");
    }
  };

  return (
    <div className="row h-100 align-items-center bg">
      <div className="col bg-secondary">
        <div className="row justify-content-center h-100 align-items-center mb-5">
          <div
            className={
              "col-10 col-lg-4 pt-3 loginWraper" +
              (!isMobile ? " loginWrapperWidth " : "")
            }
          >
            <div className="row justify-content-center align-items-center mb-4">
              <img className="img-logo" src="/logo.jpg" alt="logo" />
            </div>
            <div className="row justify-content-center text-center h4 title mb-4">
              Plataforma de garantías
            </div>
            <Form
              className="mt-2"
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={signin}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                className="authInput"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Introduzca su dirección de correo!",
                  },
                  {
                    type: "email",
                    message: "Introduzca una dirección de correo válida!",
                  },
                ]}
              >
                <Input className="emailInput" placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                className="authInput"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Porfavor introduzca su contraseña!",
                  },
                ]}
              >
                <Input.Password
                  className="passInput"
                  placeholder="Contraseña"
                />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

              <Form.Item>
                <div className="row p-3 mt-4 authButton">
                  <Button
                    loading={loading}
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    {loading ? (
                      ""
                    ) : (
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">Entrar</div>
                        <div className="col-4">
                          <div className="row h-100 justify-content-end align-items-center">
                            <ArrowRightOutlined className="w-auto mx-2" />
                          </div>
                        </div>
                      </div>
                    )}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
