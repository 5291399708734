import { Fragment } from "react";
import "./ErrorComponent.css";

function ErrorComponent() {
    console.log("HOLA..................")
  return (
    <Fragment>
      <div className="noise"></div>
      <div className="overlay"></div>
      <div className="terminal">
        <h1>
          Error <span className="errorcode">403</span>
        </h1>
        <p className="output">
          No tienes permiso para acceder a esta página. 
          ¿Qué intentas hacer?
        </p>
        <p className="output">
          Por favor  <a className="link" href="/">ve atrás</a> o{" "}
          <a className="link" href="/">Regresa al inicio</a>.
        </p>
        <p className="output">Buena suerte.</p>
      </div>
    </Fragment>
  );
}

export default ErrorComponent;
