import { Vault } from "./Vault";
import { VaultLogger } from "./VaultLogger";

export class VaultStorage {
    public static get<Vault>(): Vault | null {
        const item = localStorage.getItem(Vault.VAULT_STORAGE_NAME);
        if (item) {
            return JSON.parse(item);
        }
        return null;
    }

    public static set(vault: Vault) {
        const vaultStr = JSON.stringify(vault);
        VaultLogger.logs("VaultStorage: set: " + vaultStr);
        localStorage.setItem(Vault.VAULT_STORAGE_NAME, vaultStr);
    }

    public static update() {
        const vaultStr = JSON.stringify(Vault.getInstance(), null, 2);
        VaultLogger.logs("VaultStorage set: " + vaultStr);
        localStorage.setItem(Vault.VAULT_STORAGE_NAME, vaultStr);
    }

    public static clear() {
        localStorage.removeItem(Vault.VAULT_STORAGE_NAME);
    }
}