import { Button, List, message, Popconfirm, Modal, Form, Input } from "antd";
import Table, { ColumnType } from "antd/lib/table";
import { PutHook, useGet, usePost, usePut } from "../../services/Base.service";
import {
  AppstoreAddOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { Fragment, useEffect, useState } from "react";
import { BrandModel } from "../../model/Brands.model";
import React from "react";
import { deleteBrandFromApi } from "../../services/Brand.service";
import { EditBrand } from "./EditBrand";
import "./SearchBrand.css";
import { isUserAdmin } from "../../services/Auth.service";
import ErrorComponent from "../../components/error/ErrorComponent";

export function SearchBrands() {
  const { data, loading, error, reuse } = useGet("brand");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingUpdateBrand, setIsLoadingUpdateBrand] = useState(false);
  const [brandToUpdate, setBrandToUpdate] = useState<BrandModel>();

  const buscar = () => {
    reuse();
  };

  useEffect(() => {
    if (error) {
      message.error("Ocurrió un error trayendo los datos");
    }
  }, [error]);

  const openUpdateBrand = (brand: BrandModel) => {
    setIsUpdate(true);
    setBrandToUpdate({ ...brand });
    setIsModalOpen(true);
  };

  useEffect(() => {
    //form.resetFields();
  }, [brandToUpdate]);

  const openCreateBrand = () => {
    //form.resetFields();
    setIsUpdate(false);
    setIsModalOpen(true);
    setBrandToUpdate({
      name: "",
      description: "",
    });
  };

  const deleteBrand = (brand: BrandModel) => {
    deleteBrandFromApi(brand!).then(() => {
      message.success(`Marca ${brand?.name} eliminada`);
      reuse();
    });
  };

  const columns: ColumnType<any>[] = [
    {
      title: "Marca",
      dataIndex: "name",
      key: "name",
      render(text: string, brand: BrandModel) {
        return (
          <Fragment>
            <div>
              {text} <br />{" "}
              <div className="text-muted">{brand.description}</div>
            </div>
            <div className="mt-2">
              <b>Especificaciones</b>
              <List
                size="small"
                bordered
                dataSource={brand.specifications || []}
                renderItem={(item) => (
                  <List.Item key={item.name}>
                    <List.Item.Meta title={item.name} />
                    {item.mandatory ? (
                      <div className="mandatoryItem">
                        <CheckCircleOutlined /> Obligatorio
                      </div>
                    ) : (
                      <div className="optionalItem">
                        <QuestionCircleOutlined /> Opcional
                      </div>
                    )}
                  </List.Item>
                )}
              />
            </div>
          </Fragment>
        );
      },
    },
    {
      title: "Acciones",
      key: "acciones",
      width: "30%",
      render: (_, brand: BrandModel) => {
        return (
          <div className="row justify-content-end">
            <div className="col col-md-4">
              <div className="row mb-2">
                <Button
                  type="primary"
                  className="mx-1"
                  onClick={() => {
                    openUpdateBrand(brand);
                  }}
                >
                  Editar
                </Button>
              </div>
              <div className="row">
                <Popconfirm
                  onConfirm={() => deleteBrand(brand)}
                  title={
                    "Está seguro de querer eliminar la marca " + brand.name
                  }
                  okText="Aceptar"
                  cancelText="Cancelar"
                >
                  <Button className="mx-1" type="primary" danger>
                    Eliminar
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return isUserAdmin() ? (
    <React.Fragment>
      <div className="col">
        <div className="row mb-3 justify-content-end">
          <Search
            enterButton="Buscar"
            allowClear
            placeholder="Buscar por nombre de marca"
            onSearch={buscar}
          />
        </div>
        <div className="row justify-content-end mb-3 px-2">
          <Button
            onClick={openCreateBrand}
            className="mx-1 w-auto"
            type="primary"
          >
            <AppstoreAddOutlined />
            Crear marca
          </Button>
        </div>
        <div className="row">
          <Table
            pagination={{ position: ["topRight", "bottomRight"] }}
            columns={columns}
            loading={loading}
            dataSource={data}
          />
        </div>
      </div>
      {brandToUpdate ? (
        <EditBrand
          isUpdate={isUpdate}
          brandToUpdate={brandToUpdate}
          //setBrandToUpdate={setBrandToUpdate}
          isModalOpen={isModalOpen}
          isLoadingUpdateBrand={isLoadingUpdateBrand}
          setIsLoadingUpdateBrand={setIsLoadingUpdateBrand}
          setIsModalOpen={setIsModalOpen}
          getAll={reuse}
        />
      ) : null}
    </React.Fragment>
  ) : <ErrorComponent />;
}
