import { Table, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { TicketModel } from "../../model/Ticket.model";
import { getOcNumberFromCorrespondingState } from "../../services/Ticket.service";
import EditButton from "../editbutton/EditButton";
import TicketStateTag from "../state-tag/TicketStateTag";

const { Text } = Typography;

function TicketTable(props: { tickets: TicketModel[] }) {
  const columns: ColumnType<TicketModel>[] = [
    {
      title: "Datos del ticket",
      dataIndex: "otName",
      key: "otName",
      render: (nombre: string, ticket: TicketModel) => {
        return (
          <div key={"ot-" + ticket.id}>
            <Paragraph copyable className="mb-0">
                N° {ticket.correlative}
            </Paragraph>
            {new Date(ticket.date).toLocaleString()}
            <br />
            {nombre}
            {getOcNumberFromCorrespondingState(ticket) ? (
              <Fragment>
                <br />
                <div className="w-auto">Número de pedido:</div>
                <Paragraph copyable>
                  {getOcNumberFromCorrespondingState(ticket)}
                </Paragraph>
              </Fragment>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      render: (_: string, ticket: TicketModel) => {
        return (
          <div key={"state-" + ticket.id + ticket.actualState}>
            <TicketStateTag
              text={ticket.actualState}
              state={ticket.actualState}
            />
          </div>
        );
      },
    },
    {
      title: "Asesor",
      dataIndex: "asesorName",
      key: "asesor",
      render: (text: string, ticket: TicketModel) => {
        return (
          <div key={"mail-" + ticket.id + ticket.asesorEmail}>
            {text} /{" "}
            <a href={"mailto:" + ticket.asesorEmail}>{ticket.asesorEmail}</a>
          </div>
        );
      },
    },
    {
      title: "Descripción de la falla",
      dataIndex: "failDetail",
      key: "failDetail",
    },
    {
      title: "Respuestos solicitados",
      key: "replacementRequest",
      dataIndex: "replacementRequest",
    },
    {
      title: "Centro",
      dataIndex: "centerName",
      key: "centerName",
    },
    {
      title: "Acciones",
      key: "actions",
      render: (text: string, ticket: TicketModel) => (
        <div key={"actions-" + ticket.id} className="row">
          <Link to={"/view/" + ticket.id} target="_blank" rel="noreferrer">Ver detalles</Link>
          <EditButton ticket={ticket} />
        </div>
      ),
    },
  ];
  const columnsXs: ColumnType<TicketModel>[] = [
    {
      title: "Datos del ticket",
      dataIndex: "otName",
      key: "otNamexs",
      render: (text: string, ticket: TicketModel) => (
        <div key={"data" + ticket.id}>
          <div>{text}</div>
          <div>{new Date(ticket.date).toLocaleString()}</div>
          <div>{ticket.asesorName}</div>
          <div>{ticket.asesorEmail}</div>
          <div>{ticket.centerName}</div>
          <div>
            <TicketStateTag
              text={ticket.actualState}
              state={ticket.actualState}
            />{" "}
          </div>
          {getOcNumberFromCorrespondingState(ticket) ? (
            <Fragment>
              <div className="w-auto">Número de pedido:</div>
            
                <Paragraph copyable>
                  <b>{getOcNumberFromCorrespondingState(ticket)}</b>
                </Paragraph>
            
            </Fragment>
          ) : null}
        </div>
      ),
    },
    {
      title: "Acciones",
      key: "actions",
      render: (text: string, record: any) => (
        <div className="row" key={"actions" + record.id}>
          <Link to={"/view/" + record.id}>Ver detalles</Link>
          <div className="mt-3">
            <EditButton ticket={record} />
          </div>
        </div>
      ),
    },
  ];
  return (
    <Fragment>
      <Table
        className="d-none d-lg-block"
        columns={columns}
        dataSource={props.tickets}
      />

      <Table
        className="d-block d-lg-none"
        columns={columnsXs}
        pagination={{ pageSize: 10 }}
        dataSource={props.tickets}
      />
    </Fragment>
  );
}

export default TicketTable;
