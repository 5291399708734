import { Button, Form, List, message, Popconfirm, Modal, Switch } from "antd";
import Search from "antd/lib/input/Search";
import { useEffect, useRef, useState } from "react";
import { BrandModel, BrandSpecificationModel } from "../../model/Brands.model";
import { PutHook, usePut } from "../../services/Base.service";
import { EditBrand } from "./EditBrand";

function SpecList(props: {
  brandToUpdate: BrandModel;
  setBrandToUpdate: Function;
  isModalOpen: boolean;
  getAll: Function;
}) {
  const [specs, setSpecs] = useState<BrandSpecificationModel[]>([]);
  const inputNewspec: any = useRef(null);

  // useEffect(() => {
  //   refresh();
  // });

  useEffect(() => {
    refresh();
  }, [props.isModalOpen, props.brandToUpdate]);

  const createSpec = (value: string) => {
    const indexValue: number = specs.findIndex((spec: BrandSpecificationModel) => spec.name === value);
    if (value && indexValue === -1) {
      console.log("indexValue: " + indexValue + ", Se agrega: " + value);
      const newSpec: BrandSpecificationModel = {name: value, mandatory: true}
      const newSpecs = [...specs, newSpec];
      setSpecs(newSpecs);
      props.brandToUpdate.specifications = newSpecs;
      props.setBrandToUpdate({ ...props.brandToUpdate });
    } else if (indexValue !== -1) {
      message.error("El valor ya existe");
    }
  };

  const refresh = () => {
    setSpecs(props.brandToUpdate?.specifications || []);
    const newcurrent = { ...inputNewspec.current };
    newcurrent.input.value = "";
    newcurrent.input.defaultValue = "";
    inputNewspec.current = { ...newcurrent };
  };

  const deleteSpec = (spec: BrandSpecificationModel) => {
    const newSpecs = specs.filter((s: BrandSpecificationModel) => s.name !== spec.name);
    setSpecs([...newSpecs]);
    props.brandToUpdate.specifications = newSpecs;

    props.setBrandToUpdate({ ...props.brandToUpdate });
    console.log("Holai");
  };

  const updateMandatory = (value: boolean, spec: BrandSpecificationModel) => {
    const newSpecs = specs.map((s: BrandSpecificationModel) => {
      if (s.name === spec.name) {
        s.mandatory = value;
      }
      return s;
    });
    setSpecs([...newSpecs]);
    props.brandToUpdate.specifications = newSpecs;
    props.setBrandToUpdate({ ...props.brandToUpdate });
  }

  return (
    <div className="col">
      <div className="row">
        <Search
          ref={inputNewspec}
          enterButton="Agregar"
          allowClear
          placeholder="Escriba el nombre de la especificación"
          onSearch={createSpec}
        />
      </div>
      <div className="row mt-3 px-2">
        {specs ? (
          <List
            size="small"
            bordered
            dataSource={specs! || []}
            renderItem={(item: BrandSpecificationModel) => (
              <List.Item key={item.name}>
                <List.Item.Meta title={item.name} />
                <div>
                    <Switch checkedChildren="Obligatorio" 
                    checked={item.mandatory}
                    unCheckedChildren="Opcional" className="mx-3" defaultChecked 
                    onChange={(value: boolean) =>{
                      updateMandatory(value, item);
                    }} />
                  <Popconfirm
                    title={
                      "Está seguro de querer eliminar la especificación: " +
                      item.name
                    }
                    okText="Aceptar"
                    cancelText="Cancelar"
                    onConfirm={() => deleteSpec(item)}
                  >
                    <Button type="primary" size="small" danger>
                      Eliminar
                    </Button>
                  </Popconfirm>
                </div>
              </List.Item>
            )}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SpecList;
