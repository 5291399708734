import { Checkbox, Form, Input, Modal } from "antd";
import { Fragment, useEffect, useState } from "react";
import UploadComponent from "../../../components/upload/uploadComponent";

function ConfirmUpdateState(props: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  title: string;
  isGoBack: boolean;
  callBack: Function;
  withOcNumber: boolean;
  newState: string;
  isStateImgRequired?: boolean;
}) {
  const [form] = Form.useForm();
  const [withComments, setWithComments] = useState(true);
  const [evidenceValue, setEvidenceValue] = useState<string | undefined>(
    undefined
  );
  const [secondEvidenceValue, setSecondEvidenceValue] = useState<string | undefined>(
    undefined
  );

  const [isEvidencesOk, setIsEvidencesOk] = useState<boolean>(true);

  useEffect(() => {
    form.resetFields();
    setWithComments(true);

    if (props.isStateImgRequired) {
      setIsEvidencesOk(false);
    }
  }, [props.visible]);

  function calculateIsAllEvidencesOk(evidence: string | undefined, secondEvidence: string | undefined) {
    const isOK = evidence && secondEvidence;
    console.log("evidence", evidence);
    console.log("secondEvidence", secondEvidence);

    setIsEvidencesOk(!!isOK);
  }

  const executeCallBack = (values: any) => {
    if (props.callBack) {
      console.log("Values", values);

      props.callBack(values.description, values.ocNumber, values.evidence, values.secondEvidence);
    }

    props.setVisible(false);
  };

  return (
    <Modal
      visible={props.visible}
      title={(props.isGoBack ? 'Volver ticket a estado: ' : "Cambiar estado a ") + props.newState + "?"}
      okButtonProps={{ disabled: !isEvidencesOk }}
      onOk={async () => {
        form.submit();
      }}
      onCancel={() => {
        props.setVisible(false);
      }}
    >
      <div className="col-12 px-2">
        <Form
          onFinish={(values) => {
            values.evidence = evidenceValue;
            values.secondEvidence = secondEvidenceValue;
            executeCallBack(values);
          }}
          layout="vertical"
          name="basic"
          form={form}
        >
          <div className="col-12">
            <div className="row" style={{ display: "block" }}>
              {props.withOcNumber && !props.isGoBack ? (
                <Fragment>
                  <Form.Item
                    label="Número de la órden"
                    initialValue={""}
                    name="ocNumber"
                    rules={[
                      { required: true, message: "El campo es obligatorio!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Fragment>
              ) : null}
              {props.isStateImgRequired && !props.isGoBack ? (
                <>
                  <Form.Item
                    label="Evidencia 1 del cambio (obligatorio)"
                    initialValue={""}
                    name="evidence"
                  >
                    <Input value={evidenceValue} hidden />
                    <div >
                      <UploadComponent
                        maxHeight={200}
                        replaaceWithImage={true}
                        callBack={(url: string) => {
                          console.log("Use de la imagen", url);
                          setEvidenceValue(url);
                          calculateIsAllEvidencesOk(url, secondEvidenceValue);
                        }}
                        callBackDelete={() => {
                          setEvidenceValue(undefined);
                          calculateIsAllEvidencesOk(undefined, secondEvidenceValue);
                        }}
                      ></UploadComponent>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="Evidencia 2 del cambio (obligatorio)"
                    initialValue={""}
                    name="secondEvidence"
                  >
                    <Input value={secondEvidenceValue} hidden />
                    <div >
                      <UploadComponent
                        maxHeight={200}
                        replaaceWithImage={true}
                        callBack={(url: string) => {
                          console.log("Use de la imagen", url);
                          setSecondEvidenceValue(url);
                          calculateIsAllEvidencesOk(evidenceValue, url);
                        }}
                        callBackDelete={() => {
                          setSecondEvidenceValue(undefined);
                          calculateIsAllEvidencesOk(evidenceValue, undefined);
                        }}
                      ></UploadComponent>
                    </div>
                  </Form.Item>
                </>
              ) : null}
              {withComments ? (
                <Fragment>
                  <Form.Item
                    className="w-auto mt-2"
                    label={props.isGoBack ? "Descripción de la vuelta atrás" : "Descripción del cambio de estado"}
                    name="description"
                    initialValue={""}
                    rules={[
                      { required: true, message: "El campo es obligatorio!" },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Fragment>
              ) : null}
              <div className="row justify-content-end m-0 p-0">
                <Checkbox
                  checked={!withComments}
                  style={{ float: "right", display: "contents" }}
                  onChange={(event) => {
                    setWithComments(!event.target.checked);
                  }}
                >
                  Sin comentarios
                </Checkbox>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ConfirmUpdateState;
