import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import useWindowSize from "../../../../../hooks/WindowSizeHook";
import { BarCharModel } from "../BarChartDataProvider";

function BarChart(props: { data: BarCharModel[]; title: string }) {
  const [data, setData] = useState<BarCharModel[]>([]);
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  const style = {
    height: isMobile ? "500px" : "600px",
    width: "100%",
  };

  const dataNames = props.data.map((i) => i.name);

  useEffect(() => {
    const dataObtained = props.data;
    setData([...dataObtained]);
  }, []);

  useEffect(() => {
    setIsMobile(size.width < 581);
  }, [size.width]);

  let option = {
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
          title: "Guardar como imagen",
        },
      },
    },
    tooltip: {},
    xAxis: {
      type: "category",
      data: dataNames,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: data.map((i) => ({value: i.value, itemStyle: {
            color: i.itemStyle.color// "#5470c6"
        }})),
        type: "bar",
        label: {
          show: true,
          position: 'inside'
        },
      },
    ],
  };

  return (
    <div>
      <ReactEcharts option={option} style={style} />
    </div>
  );
}

export default BarChart;
