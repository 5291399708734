import { Badge, Button } from "antd";
import BadgeStatus from "./BagdeStatus";
import "./UpdateTicketButton.css";

function UpdateTicketButton(props: {
    className?: string;
    type: string
    onClick?: () => void
    text: string,
    color?: string
}) {
    return ( 
        <Button
          className={"w-auto mt-3 " + props.type + " " + props.className}
          onClick={props.onClick}
          type="primary"
          size="large"
        >
            <BadgeStatus type={props.type} text={props.text}
            style={{
                color: props.color
              }}
             />
        </Button>
     );
}

export default UpdateTicketButton;