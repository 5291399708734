import {
  ExportOutlined,
  SearchOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import ErrorComponent from "../../components/error/ErrorComponent";
import { exportToCSV } from "../../components/ticket-table/ExportData";
import SearchTicketFilters from "../../components/ticket-table/SearchTicketFilters";
import TicketTable from "../../components/ticket-table/TicketTable";
import { SearchTicketModel } from "../../model/SearchTicketModel";
import { TicketModel } from "../../model/Ticket.model";
import { isUserAdmin } from "../../services/Auth.service";
import { RolesContext } from "../../services/Roles.service";
import { searchTickets, StatessContext } from "../../services/Ticket.service";
import BarChart from "./components/charts/bar-chart/bar-chart";
import { getBarDataByRoles, getBarDataByState } from "./components/charts/BarChartDataProvider";
import {
  getPieDataByCenter,
  getPieDataByState,
} from "./components/charts/ChartDataProvider";
import Piechart, { PieChartData } from "./components/charts/pie-chart/pieChart";
import "./Dashboard.css";

function Dashboard() {
  const csvLink: any = useRef(null);
  const states = useContext(StatessContext);
  // const roles = useContext(RolesContext);
  const [searchModel, setSearchModel] = useState<SearchTicketModel>({});
  const [tickets, setTickets] = useState<TicketModel[]>([]);
  const [dataToExport, setDataToExport] = useState<any[]>([]);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
  // const [ticketsByStatePie, setTicketsByStatePie] = useState<any[] | null>(
  //   null
  // );
  // const [ticketsByCentersPie, setTicketsByCentersPie] = useState<
  //   PieChartData[] | null
  // >(null);
  // const [ticketsByRoleBar, setTicketsByRoleBar] = useState<any[] | null>(null);
  const [ticketsByStateBar, setTicketsByStateBar] = useState<any[] | null>(null);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (tickets && tickets.length > 0 && states && states.length > 0) {
      const barDataByState = getBarDataByState(tickets, states);
      if (barDataByState && barDataByState.length > 0) {
        setTicketsByStateBar(barDataByState);
      }
      // const pieDataByCenter = getPieDataByCenter(tickets, []);
      // if (pieDataByCenter && pieDataByCenter.length > 0) {
      //   setTicketsByCentersPie(pieDataByCenter);
      // }
    }
  }, [tickets, states]);

  // useEffect(() => {
  //   if (tickets && tickets.length > 0 && roles && roles.length > 0) {
  //     const barDataByState = getBarDataByRoles(tickets, roles);
  //     if (barDataByState && barDataByState.length > 0) {
  //       console.log("barDataByState", barDataByState);
  //       setTicketsByRoleBar(barDataByState);
  //     }
  //   }
  // }, [tickets, roles]);

  const search = () => {
    setLoadingSearch(true);
    searchTickets(searchModel).then((tickets) => {
      setTickets(tickets);
      setLoadingSearch(false);
    });
  };

  return isUserAdmin() ? (
    <div className="col">
      <div className="row mt-2 mb-3">
        <div className="col">
          {ticketsByStateBar ? (
            <BarChart data={ticketsByStateBar} title="Ticket por Rol" />
          ) : (
            <div className="row justify-content-center">
              <div className="w-auto" style={{ height: 200 }}>
                "Creando gráfico de roles"
              </div>
              <div className="w-auto">
                <LoadingOutlined className="w-auto" />
              </div>
            </div>
          )}
        </div>
        {/* <div className="col-12">
          {ticketsByRoleBar ? (
            <BarChart data={ticketsByRoleBar} title="Ticket por Rol" />
          ) : (
            <div className="row justify-content-center">
              <div className="w-auto" style={{ height: 200 }}>
                "Creando gráfico de roles"
              </div>
              <div className="w-auto">
                <LoadingOutlined className="w-auto" />
              </div>
            </div>
          )}
        </div> */}
        {/* <div className="col-12 col-lg-6">
          {ticketsByStatePie ? (
            <Piechart title="Tickets por estado" data={ticketsByStatePie} />
          ) : (
            <div className="row justify-content-center">
              <div className="w-auto">"Inicializando gráfico"</div>
              <div className="w-auto">
                <LoadingOutlined className="w-auto" />
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-6">
          {ticketsByCentersPie ? (
            <Piechart title="Tickets por centro" data={ticketsByCentersPie} />
          ) : (
            <div className="row justify-content-center">
            <div className="w-auto">"Inicializando gráfico"</div>
            <div className="w-auto">
              <LoadingOutlined className="w-auto" />
            </div>
          </div>
          )}
        </div> */}
        {/* <div className="col-12 col-lg-6">
          {ticketsByStateLine ? <LineChart data={ticketsByStateLine} /> : "Cargando gráfico de lineas"}
        </div>  */}
      </div>
      <div className="row justify-content-center">
        <SearchTicketFilters
          searchModel={searchModel}
          setSearchModel={setSearchModel}
          showStateSelection={true}
        />
      </div>
      <div className="row mx-2 mt-2 justify-content-end align-items-end">
        <div className="w-auto">{tickets.length} resultados</div>
      </div>
      <div className="row mt-2 mb-3 mx-0 justify-content-end w-100 align-items-center">
        <Button disabled={loadingSearch}
          type="primary" onClick={search} className="w-auto mx-2">
          {!loadingSearch && <SearchOutlined />}
          {loadingSearch && <span className="loader-xs"></span>}
          &nbsp;
          Buscar
        </Button>
        <CSVLink
          ref={csvLink}
          target="_blank"
          className="w-auto"
          filename="data.csv"
          data={dataToExport}
          asyncOnClick={true}
          onClick={(event, done) => {
            const data: any[] = exportToCSV(tickets);
            setDataToExport([...data]);
            done(true);
          }}
        >
          <Button
            disabled={loadingSearch}
            type="default" loading={isExportLoading} className="w-auto">
            <ExportOutlined />
            Exportar resultados
          </Button>
        </CSVLink>
      </div>
      <div className="row">
        <TicketTable tickets={tickets}></TicketTable>
      </div>
    </div>
  ) : (
    <ErrorComponent />
  );
}

export default Dashboard;
