import { DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useState } from "react";
import {
  beforeUpload,
  doUpload,
  isImage,
} from "../../pages/create-ticket/UploadEvidenceService";
import MediaImage from "../media-img/MediaImg";

function UploadComponent(props: {
  disabled?: boolean;
  replaaceWithImage: boolean;
  callBack: Function;
  maxHeight?: number;
  callBackDelete?: Function;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastImgName, setLastImgName] = useState<string | null>(null);
  const [lastImgUrl, setLastImgUrl] = useState<string | null>(null);

  return (
    <>
      {props.replaaceWithImage && lastImgUrl ? (
        <>
          <div className="row justify-content-end mb-2">
            <Button
              type="primary"
              danger
              onClick={() => {
                setLastImgName(null);
                setLastImgUrl(null);
                if (props.callBackDelete) {
                  props.callBackDelete();
                }
              }}
              className="w-auto p-2"
              icon={<DeleteOutlined />}
            >

            </Button>
          </div>


          <div className="row justify-content-center m-0 p-0" >
            <MediaImage
              maxHeight={props.maxHeight}
              mediaUrl={lastImgUrl}
              isImage={isImage(lastImgName || "")}
            />
          </div>

        </>
      ) : (
        <Upload
          disabled={props.disabled || isLoading}
          listType="picture-card"
          className="uploader w-100"
          showUploadList={false}
          customRequest={(requestData: any) => {
            setIsLoading(true);
            console.log("requestData.file.name", requestData.file.name);
            setLastImgName(requestData.file.name);
            doUpload({
              file: requestData.file,
              filename: requestData.file.name,
            })
              .then((response: { filename: string }) => {
                console.log("response :::: " + response.filename);
                setLastImgUrl(response.filename);
                props.callBack(response.filename);
              })
              .catch((error: any) => {
                console.log("Error subiendo imagen.");
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          beforeUpload={beforeUpload}
        >
          <div className="row">
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Subir imagen/video</div>
          </div>
        </Upload>
      )}
    </>
  );
}

export default UploadComponent;
