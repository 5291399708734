import { Button, Popconfirm, Table, Tag, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import React from "react";
import { RoleModel } from "../../../model/Role.model";
import { UserModel } from "../../../model/User.model";
import { GetHook } from "../../../services/Base.service";
import { useDeleteUser, useGetRoles } from "../User.service";

const { Text } = Typography;

function UsersTable(props: {
  setIsEditUser: Function;
  setIsEditUserOpen: Function;
  setUserToUpdate: Function;
  setIsChangePasswordOpen: Function;
  data: UserModel[];
  search: Function;
  isLoading: boolean;
}) {
  const rolesHook: GetHook = useGetRoles<RoleModel>();

  const deleteUserHook = useDeleteUser();

  const getRoleColor = (role: string) => {
    return rolesHook.data.find((r: RoleModel) => r.role === role)?.color;
  };

  const deleteUser = (user: UserModel) => {
    deleteUserHook.use(user.id!).then(() => {
      props.search(true);
    });
  };

  const columns: ColumnType<UserModel>[] = [
    {
      title: "Datos del usuario",
      dataIndex: "email",
      key: "email",
      render: (text: string, user: UserModel) => (
        <React.Fragment>
          <div className="row">{user.fullName}</div>
          <div className="row">{text}</div>
          <div className="row">{user.center}</div>
          <div className="row">Roles: </div>
          <div className="row">
            {user.roles?.map((rol: string) => {
              return (
                <div className="w-auto" key={rol}>
                  <Tag color={getRoleColor(rol)}>{rol}</Tag>
                </div>
              );
            })}
          </div>
          <div className="row">Estado:</div>
          <Tag color={user.status === "ACTIVE" ? "purple" : "red"}>
            {user.status === "ACTIVE" ? "Activo" : "Inactivo"}
          </Tag>
        </React.Fragment>
      ),
    },
    {
      title: "Acciones",
      key: "actions",
      width: "5%",
      render: (text: string, record: any) => (
        <div className="row">
          <Button
            onClick={() => {
              props.setIsEditUser(true);
              props.setIsEditUserOpen(true);
              props.setUserToUpdate(record);
            }}
            className="mb-2"
            type="primary"
          >
            Editar
          </Button>
          <Popconfirm
            onConfirm={() => deleteUser(record)}
            title={
              "Está seguro de querer eliminar al usuario " + record.fullName
            }
            okText="Aceptar"
            cancelText="Cancelar"
          >
            <Button type="primary" danger>
              Eliminar
            </Button>
          </Popconfirm>

          <Button
            onClick={() => {
              props.setUserToUpdate(record);
              props.setIsChangePasswordOpen(true);
            }}
            className="mt-2"
            type="default"
          >
            Cambiar contraseña
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      loading={props.isLoading}
      columns={columns}
      dataSource={props.data}
    />
  );
}

export default UsersTable;
