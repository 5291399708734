import { Form, Input, Modal, Select, Tag, Typography } from "antd";
import { Fragment, useEffect, useState } from "react";
import { UserModel } from "../../../model/User.model";
import { GetHook } from "../../../services/Base.service";
import { findCenters } from "../../../services/Center.service";
import { Buffer } from "buffer";

import {
  useCreateUser,
  useGetStatuses,
  useUpdateUser,
} from "../User.service";
import { RoleModel } from "../../../model/Role.model";
import { CenterModel } from "../../../model/CenterModel";
const { Text } = Typography;


function EditUser(props: {
  isEdit: boolean;
  isModalOpen: boolean;
  setIsModalOpen: Function;
  userToUpdate: UserModel;
  getAll: Function;
  rolesHook: GetHook;
}) {
  const [form] = Form.useForm();
  const statusesHook: GetHook = useGetStatuses<string>();
  const createUserHook = useCreateUser();
  const updateUserHook = useUpdateUser();
  const [centers, setCenters] = useState<CenterModel[]>([]);

  const finish = (values: any) => {
    if (props.isEdit) {
      updateUserHook.use({ ...props.userToUpdate, ...values });
    } else {
      values.password = Buffer.from(values.password).toString("base64");
      values.passr = null;
      console.log("Centers")
      console.log(values)
      createUserHook.use({ ...props.userToUpdate, ...values });
    }

    props.setIsModalOpen(false);
  };

  useEffect(() => {
    findCenters()
    .then((centers: CenterModel[]) => {
      setCenters([...centers]);
    });
  }, []);

  useEffect(() => {
    props.getAll(true);
  }, [createUserHook.data, updateUserHook.data]);

  useEffect(() => {
    form.resetFields();
  }, [props.userToUpdate]);

  return (
    <Modal
      width="100%"
      style={{ top: "10px" }}
      title={props.isEdit ? "Editar usuario" : "Crear usuario"}
      visible={props.isModalOpen}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        props.setIsModalOpen(false);
      }}
    >
      <Form
        onFinish={(values) => {
          finish(values);
        }}
        className="h-auto"
        layout="vertical"
        name="basic"
        form={form}
      >
        <Form.Item
          label="Nombre completo"
          initialValue={props.userToUpdate?.fullName}
          name="fullName"
          rules={[{ required: true, message: "El campo es obligatorio!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          initialValue={props.userToUpdate?.email}
          name="email"
          rules={[{ required: true, message: "El campo es obligatorio!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Centro"
          name="centers"
          initialValue={props.userToUpdate?.centers}
          rules={[{ required: true, message: "El campo es obligatorio!" }]}
        >
          <Select mode="multiple" size="large">
            {centers.map((center: CenterModel) => (
              <Select.Option value={center.name}>{center.name} {' '} / {' '}
                  <Text type="secondary">{center.description}</Text>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Roles"
          initialValue={props.userToUpdate?.roles}
          name="roles"
        >
          <Select mode="multiple" size="large">
            {props.rolesHook.data?.map((role: RoleModel) => (
              <Select.Option value={role.role}>
                <Tag color={role.color}>{role.role}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Estado"
          name="status"
          initialValue={props.userToUpdate?.status}
          rules={[{ required: true, message: "El campo es obligatorio!" }]}
        >
          <Select>
            {statusesHook.data?.map((status: string) => (
              <Select.Option value={status}>{status === "ACTIVE" ? 'Activo' : 'Inactivo'}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {!props.isEdit ? (
          <Fragment>
            <Form.Item
              label="Contraseña"
              initialValue=""
              name="password"
              hasFeedback
              rules={[{ required: true, message: "El campo es obligatorio!" }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Repita la contraseña"
              initialValue=""
              name="passr"
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: "El campo es obligatorio!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "El password ingresado no es igual que el anterior!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password  />
            </Form.Item>
          </Fragment>
        ) : null}
      </Form>
    </Modal>
  );
}

export default EditUser;
