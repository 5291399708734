export interface TicketModel {
    id: string;
    otName: string;
    asesorId: string;
    asesorName: string;
    asesorEmail: string;
    failDetail: string;
    replacementRequest: string;
    centerId: string;
    centerName: string;
    states: TicketStateModel[];
    date: Date;
    readableDate: string;
    actualState: string;
    sparePartNumber: string;
    mediaBrandCheck: MediaBrandCheckModel;
    correlative: number;

}

export interface TicketStateModel {
    state: string;
    stateDescription: string;
    stateDate: Date;
    fullName: string;
    userEmail: string;
    role: string;
    stateOrder: number;
    readableDate: string;
    ocNumber: string;
    evidence: string;
    secondEvidence: string;
    isGoBack: boolean;
}

export interface MediaBrandCheckModel {
    brandName: string;
    brandId: string;
    medias: MediaModel[];
}

export interface MediaModel {
    mediaUrl: string;
    brandSpecification: string;
    checked: boolean
    mediaType: string;
    /**
     * Only for front
     */
    loading?: boolean;
    base64Content?: string;
    uploaded?: boolean;
    filename?: string;
}

export type al = 'EVALUACION' | 'RECHAZADO' | 'SOLICITUD_RESPUESTO' | 'REPUESTO_SOLICITADO' | 'REPUESTO_RECEPCIONADO' | 'FINALIZADO'
export type TicketState = { index: number, name: string, color: string };
export type StateColor = "success" | "error" | "processing" | "warning" | "default" | "#191919";
export class TICKET_STATE {

    static EVALUACION = 'EVALUACION';
    static RECHAZADO = 'RECHAZADO';
    static SOLICITUD_RESPUESTO = 'SOLICITUD_RESPUESTO';
    static REPUESTO_SOLICITADO = 'REPUESTO_SOLICITADO';
    static REPUESTO_RECEPCIONADO = 'REPUESTO_RECEPCIONADO';
    static FINALIZADO = 'FINALIZADO';

    static states = [
        { 'EVALUACION': { index: 0, name: 'evaluacion', color: "success" } },
        { 'RECHAZADO': { index: 1, name: 'rechazado', color: "error" } },
        { 'SOLICITUD_RESPUESTO': { index: 2, name: 'solicitud_respuesta', color: "processing" } },
        { 'REPUESTO_SOLICITADO': { index: 3, name: 'repuesto_solicitado', color: "warning" } },
        { 'REPUESTO_RECEPCIONADO': { index: 4, name: 'repuesto_recepcionado', color: "default" } },
        { 'FINALIZADO': { index: 5, name: 'finalizado', color: "#191919" } },
    ]

    static getColor(_state: 'EVALUACION' | 'RECHAZADO' | 'SOLICITUD_RESPUESTO' | 'REPUESTO_SOLICITADO'
        | 'REPUESTO_RECEPCIONADO' | 'FINALIZADO'
    ): StateColor {

        for (let i = 0; i < TICKET_STATE.states.length; i++) {
            const state = TICKET_STATE.states[i];
            const s = state[_state];
            if (s) {
                return s.color as StateColor;
            }

            return "default";
        }
        return "default";
    }


}