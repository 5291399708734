import { TicketModel } from "../../model/Ticket.model"

export const exportToCSV = (tickets: TicketModel[]): any[] => {
    const titles: any[] = ['id','fecha','nombre ticket','nombre asesor',
    'email asesor','falla','repuestos','centro','estado','marca']

    const data: any[] = tickets.map(ticket => {
        return [ticket.id, ticket.date, ticket.otName, ticket.asesorName,
        ticket.asesorEmail, ticket.failDetail, ticket.replacementRequest,
        ticket.centerName, ticket.actualState,  ticket.mediaBrandCheck?.brandName || 'no data'];
    })


    const out =  [[...titles], ...data]

    return out;
}