import axios from "axios";

export function uploadFile(data: {file: File, filename: string}){
    var formData = new FormData();
    formData.append("file", data.file);
    formData.append("filename", data.filename);
    formData.append("ext", data.filename.split(".").pop()!);

    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/upload-file`,
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData,
    }).then(response => {
        return response.data
    });
}