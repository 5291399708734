import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Select,
  Tag,
  Typography,
  Upload,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../../components/error/ErrorComponent";
import MediaImage from "../../components/media-img/MediaImg";
import { BrandModel, BrandSpecificationModel } from "../../model/Brands.model";
import { CenterModel } from "../../model/CenterModel";
import {
  MediaBrandCheckModel,
  MediaModel,
  TicketModel,
} from "../../model/Ticket.model";
import { UserModel } from "../../model/User.model";
import { canCreateTicket, getUser } from "../../services/Auth.service";
import {
  get,
  GetHook,
  PostHook,
  useGet,
  usePost,
} from "../../services/Base.service";
import { CenterContext } from "../../services/Center.service";
import { Task } from "../../vault/Task";
import { Vault } from "../../vault/Vault";
import "./CreateTicket.css";
import { saveTicketWorker, saveTicketWorkerCallBack } from "./TicketTask";
import {
  getBase64,
  isImage,
  isImageByType,
  tryToUpload,
} from "./UploadEvidenceService";

const { Option } = Select;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

function beforeUpload(file: any) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "video/mp4" ||
    file.type === "video/webm" ||
    file.type === "video/ogg" ||
    file.type === "video/avi" ||
    file.type === "video/mkv";
  if (!isJpgOrPng) {
    message.error("You can only upload image or video file!");
  }
  return isJpgOrPng;
}

const taskName = "Guardar ticket";

Vault.addWorkerForTask(taskName, saveTicketWorker, saveTicketWorkerCallBack);

function CreateTicket() {
  const getBrandsHook: GetHook = useGet<BrandModel>("/brand");
  const createTicketHook: PostHook = usePost<BrandModel>("/ticket");
  const [selectedBrand, setSelectedBrand] = useState<BrandModel>();
  const [selectedCenter, setSelectedCenter] = useState<CenterModel>({
    name: "",
    description: "",
  });
  const [user, setUser] = useState<UserModel>();
  const [existMandatoryBrandcheck, setExistMandatoryBrandcheck] =
    useState(true);
  const [imgUrl, setImgUrl] = useState<string>();
  const [mediaBrandCheck, setMediaBrandCheck] = useState<MediaBrandCheckModel>({
    brandName: "",
    brandId: "",
    medias: [],
  });
  const navigate = useNavigate();
  const _canCreateTicket = canCreateTicket();
  const [form] = Form.useForm();
  const [isNotConection, setIsNotConection] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editTicket, setEditTicket] = useState<TicketModel>();
  const [boxName, setBoxName] = useState<string>("");
  const centers = useContext(CenterContext);
  const [loadingCreateTicket, setLoadingCreateTicket] = useState<boolean>(false);


  useEffect(() => {
    getUserData();
    setBoxName(Vault.generateVaultSessionId());
    const timer = connectionMonitor();
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (centers && centers.length > 0) {
      const _user = getUser();
      if (_user) {
        const center: CenterModel | undefined = centers.find(
          (c: CenterModel) => c.name === _user?.centers[0]
        );

        if (center) {
          setSelectedCenter(center);
        } else {
          message.error("No se encontró el centro del usuario.");
        }
      }
    }
  }, [centers]);

  const connectionMonitor = () => {
    console.log("Seteando intervalo para verificar conexión...");
    const timer: NodeJS.Timer = setInterval(async () => {
      const isConnectionOk = await ping();
      if (!isConnectionOk) {
        setIsNotConection(true);
      } else {
        console.log("Connection ok...");
      }
    }, 30 * 1000);
    return timer;
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedCenter]);

  useEffect(() => {
    if (isNotConection) {
      message.warn(
        "Entrando en modo sin conexión. Se creará el ticket localmente y se guardará cuando se conecte a internet."
      );
    }
  }, [isNotConection]);

  const ping = async (): Promise<boolean> => {
    const ping: boolean = await get<string>(
      `${process.env.REACT_APP_TICKET_MANAGER_URL}/ping`
    )
      .then((res: any) => {
        return res === "pong";
      })
      .catch(() => false);
    return ping;
  };

  const canSaveTicket = (): boolean => {
    if (mediaBrandCheck) {
      let isAllMendatoryOk = true;

      if (selectedBrand) {
        console.log("SelectedBrand: OK ");
        for (let specification of selectedBrand?.specifications!) {
          console.log(
            "specification lenght: " + selectedBrand.specifications?.length
          );
          if (specification?.mandatory) {
            console.log("apec mandatory: " + JSON.stringify(specification));
            if (mediaBrandCheck?.medias && mediaBrandCheck?.medias.length > 0) {
              console.log(
                "Exists medias lenght: " + mediaBrandCheck?.medias.length
              );
              const mediaFromBrandSpec = mediaBrandCheck?.medias.find(
                (m) => m.brandSpecification === specification.name
              );

              if (mediaFromBrandSpec) {
                console.log("Media from brand spec: OK");
                if (!mediaFromBrandSpec.checked) {
                  console.log("mediaBrandCheck.checked: OK");
                  return false;
                }
              } else {
                console.log("Media from brand spec: NOK");
                return false;
              }
            } else {
              console.log("No medias");
              return false;
            }
          }
        }
      } else {
        return false;
      }

      if (isAllMendatoryOk) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onFinish = (values: any) => {
    setLoadingCreateTicket(true);
    console.log("Muy bien, trantando de escribir ticket...");
    const ticket: TicketModel = { ...values };
    ticket.mediaBrandCheck = { ...mediaBrandCheck };
    console.log("ticket:", JSON.stringify(ticket));

    if (!isNotConection) {
      createTicketHook.use(ticket).then((_ticket: TicketModel) => {
        message.success("Ticket creado correctamente.");
        navigate(`/view/${_ticket.id}`, { replace: true });
      });
    } else {
      message.info(
        "Modo sin conexión. Se reintentará cuando se conecte a internet."
      );
      Vault.getBox(boxName)
        .withStuff("ticket", ticket)
        .withStuff("date", new Date())
        .addTask(
          Task.fromWorker(taskName, saveTicketWorker).withCallBack(
            saveTicketWorkerCallBack
          )
        );

      navigate(`/vault`, { replace: true });
    }
  };

  const getUserData = () => {
    const _user = getUser();
    if (_user) {
      setUser({ ..._user });
    }
  };

  const handleChangeUpload = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        //setImgUrl(imageUrl);
      });
    }
  };

  const uploadButton = (spec: string) => {
    const mediaBySpec: MediaModel | undefined = mediaBrandCheck.medias.find(
      (media: MediaModel) => media.brandSpecification === spec
    );

    return (
      <div className="row">
        {mediaBySpec?.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Subir imagen/video</div>
      </div>
    );
  };

  const checkMandatorySpecs = (
    brand: BrandModel,
    _mediaBrandCheck: MediaBrandCheckModel
  ) => {
    let _existMandatoryBrandcheck = false;
    console.log("checkMandatoryMedias");

    for (let brandSpec of brand.specifications!) {
      console.log("brandSpec.mandatory: " + brandSpec.mandatory);
      if (!_existMandatoryBrandcheck && brandSpec.mandatory) {
        _existMandatoryBrandcheck = true;
      } else {
        break;
      }
    }
    setExistMandatoryBrandcheck(_existMandatoryBrandcheck);
  };

  const onChangeBrand = (value: any) => {
    mediaBrandCheck.brandId = "";
    mediaBrandCheck.brandName = "";
    mediaBrandCheck.medias = [];

    console.log("## - " + value);

    const selectedBrand = {
      ...getBrandsHook.data.find((brand: BrandModel) => brand.id === value),
    };
    Vault.getBox(boxName).saveThisStuff("selectedBrand", selectedBrand);
    console.log("selectedBrand", selectedBrand.name);
    setSelectedBrand(selectedBrand);
    mediaBrandCheck.brandId = selectedBrand.id;
    mediaBrandCheck.brandName = selectedBrand.name;
    setMediaBrandCheck({ ...mediaBrandCheck });
    checkMandatorySpecs(selectedBrand, mediaBrandCheck);
  };

  const showMedia = (spec: string | undefined) => {
    if (!spec) return false;

    const media: MediaModel | undefined = mediaBrandCheck.medias.find(
      (media: MediaModel) => media.brandSpecification === spec
    );

    if (media && media.mediaUrl) {
      console.log("media.mediaUrl:" + media.mediaUrl);
      return (
        <div className="col-12 col-sm-6">
          {/* {media.mediaType} */}
          {isImage(media.filename!) ? (
            <MediaImage
              mediaUrl={media.mediaUrl}
              name={media.brandSpecification}
              isImage={isImageByType(media.mediaType)}
              maxHeight={300}
            />
          ) : (
            <div className="row justify-content-center">
              <video className="uploadedImage" controls>
                <source
                  src={`${process.env.REACT_APP_UPLOAD_FILE_URL}/upload-file?fileName=${media.mediaUrl}`}
                  type="video/mp4"
                />
              </video>
            </div>
          )}
        </div>
      );
    } else if (media && media.base64Content) {
      return (
        <div className="col-12 col-sm-6">
          <div className="row align-items-center h-100 justify-content-center">
            {/* <img className="uploadedImage" src={media.base64Content} alt="" /> */}
            <MediaImage
              mediaUrl={media.mediaUrl}
              isImage={isImageByType(media.mediaType)}
            />
          </div>
        </div>
      );
    }
  };

  const removeImageButton = (spec: string) => {
    const media: MediaModel | undefined = mediaBrandCheck.medias.find(
      (media: MediaModel) => media.brandSpecification === spec
    );

    return media && media.mediaUrl ? (
      <div className="row px-3 h-3 h-100 mt-1 align-content-end">
        <Button
          onClick={() => {
            mediaBrandCheck.medias = mediaBrandCheck.medias.filter(
              (media: MediaModel) => media.brandSpecification !== spec
            );
            setMediaBrandCheck({ ...mediaBrandCheck });
            checkMandatorySpecs(selectedBrand!, mediaBrandCheck);
          }}
          type="primary"
          danger
        >
          {" "}
          Eliminar evidencia
        </Button>
      </div>
    ) : null;
  };

  return _canCreateTicket ? (
    <div className={isNotConection ? "row notConnection" : "row mx-1"}>
      <div className="col">
        <div className="row justify-content-center">
          Sesión de creación de ticket:
          <Typography.Title className="w-auto" level={5} style={{ margin: 0 }}>
            {boxName}
          </Typography.Title>
        </div>
      </div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Número de OT"
          name="otName"
          rules={[
            { required: true, message: "Por favor introduzca un número de OT!" },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        {user ? (
          <Form.Item hidden name="asesorId" initialValue={user.id}>
            <Input />
          </Form.Item>
        ) : null}

        {user ? (
          <Form.Item
            label="Nombre del asesor"
            name="asesorName"
            initialValue={user.fullName}
            rules={[
              { required: true, message: "Por favor introduzca un nombre !" },
            ]}
          >
            <Input readOnly />
          </Form.Item>
        ) : null}

        {user ? (
          <Form.Item
            label="Email del asesor"
            name="asesorEmail"
            initialValue={user.email}
            rules={[
              { required: true, message: "Por favor introduzca un email!" },
            ]}
          >
            <Input readOnly />
          </Form.Item>
        ) : null}
        {selectedCenter ? (
          <Form.Item hidden name="centerId" initialValue={selectedCenter.id}>
            <Input />
          </Form.Item>
        ) : null}

        <Form.Item
          name="centerName"
          label="Centro"
          initialValue={selectedCenter.name}
          rules={[
            { required: true, message: "Por favor seleccione un centro!" },
          ]}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Descripción de la falla"
          name="failDetail"
          rules={[
            {
              required: true,
              message: "Por favor introduzca el nombre de la falla!",
            },
          ]}
        >
          <Input.TextArea showCount maxLength={300} />
        </Form.Item>
        <Form.Item
          label="Código de repuesto"
          name="sparePartNumber"
          rules={[
            {
              required: true,
              message: "Por favor introduzca el código del repuesto!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Detalle del repuesto"
          name="replacementRequest"
          rules={[
            {
              required: false,
              message: "Por favor introduzca los repuestos a solicitar!",
            },
          ]}
        >
          <Input.TextArea showCount maxLength={300} />
        </Form.Item>
        <Form.Item
          name="brand"
          label="Marca"
          rules={[
            { required: true, message: "Por favor seleccione una marca!" },
          ]}
        >
          <Select onChange={onChangeBrand} placeholder="Seleccione una marca">
            {getBrandsHook.data.map((brand: any) => {
              return (
                <Option key={brand.id} value={brand.id}>
                  {brand.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {selectedBrand ? <div className="row m-1 h5">Evidencias</div> : null}

        {selectedBrand?.specifications?.map((spec: BrandSpecificationModel) => {
          return (
            <div key={spec.name + "c"} className="col p-1">
              <div
                className="row justify-content-between no-gutters mt-3"
                key={spec.name + "spec-name"}
              >
                <div className="w-auto">{spec.name + " "} </div>
                {spec.mandatory ? (
                  <div className="w-auto">
                    <Tag className="w-auto" color="blue">
                      Obligtorio
                    </Tag>
                  </div>
                ) : (
                  <div className="w-auto">
                    <Tag color="yellow">Opcional</Tag>
                  </div>
                )}
              </div>

              <div
                key={spec.name + "spec-media"}
                className="row h-100 align-items-end"
              >
                {showMedia(spec.name)}
                <div className="col h-100">
                  {!mediaBrandCheck.medias.find(
                    (media: MediaModel) =>
                      media.brandSpecification === spec.name
                  )?.checked ? (
                    <div className="row">
                      <Upload
                        disabled={createTicketHook.loading}
                        listType="picture-card"
                        className="uploader w-100"
                        showUploadList={false}
                        data={{ brandSpecification: spec.name }}
                        customRequest={(requestData: any) => {
                          tryToUpload(
                            requestData,
                            mediaBrandCheck,
                            selectedBrand,
                            setMediaBrandCheck,
                            checkMandatorySpecs,
                            isNotConection,
                            setIsNotConection,
                            "vaultBoxName_DUMMY",
                            boxName
                          );
                        }}
                        beforeUpload={beforeUpload}
                        onChange={handleChangeUpload}
                      >
                        {uploadButton(spec.name)}
                      </Upload>
                    </div>
                  ) : null}
                  {removeImageButton(spec.name)}
                </div>
              </div>
            </div>
          );
        })}

        <Form.Item>
          <Button
            disabled={!canSaveTicket()}
            loading={createTicketHook.loading}
            size="large"
            className="w-100 mt-3"
            type="primary"
            htmlType="submit"
          >
            Crear requerimiento
          </Button>
        </Form.Item>
      </Form>
      {loadingCreateTicket && <div className="loading-wrapper" >
        <div className="loading-content">
          <div style={{
            display: "flex",
            gap: "15px",
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <span className="loader"></span>
            Creando ticket...
          </div>
        </div>
      </div>}
    </div>
  ) : (
    <ErrorComponent />
  );
}

export default CreateTicket;
