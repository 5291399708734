import { BrandModel } from "../../model/Brands.model";
import { MediaBrandCheckModel, MediaModel } from "../../model/Ticket.model";
import { uploadFile } from "../../services/UploadFile.service";
import { Vault } from "../../vault/Vault";
import { isImage } from "../create-ticket/UploadEvidenceService";

export const tryToUpload = (
    requestData: any,
    mediaBrandCheck: MediaBrandCheckModel,
    selectedBrand: BrandModel,
    setMediaBrandCheck: Function,
    //checkMandatoryMedias: Function,
    checkMandatorySpecs: Function,
    isNotConection: boolean,
    setIsNotConection: Function,
    vaultBoxName: string,
    vaultSessionId: string
) => {

    const boxName = vaultSessionId;// + " ; " +  vaultBoxName;
    const getMediaFromSpec = (spec: string) => {
        return mediaBrandCheck.medias.find(
            (media: MediaModel) => spec === media.brandSpecification
        );
    };

    let mediaFromSpec: MediaModel | undefined = getMediaFromSpec(
        requestData.data.brandSpecification
    );

    if (!mediaFromSpec) {
        mediaFromSpec = {
            mediaUrl: "",
            brandSpecification: requestData.data.brandSpecification,
            checked: false,
            loading: true,
            mediaType: isImage(requestData.file.name) ? "IMAGE" : "VIDEO"
        };
        mediaBrandCheck.medias.push(mediaFromSpec);
    }

    setMediaBrandCheck({ ...mediaBrandCheck });

    if (!isNotConection) {
        _doUpload({
            file: requestData.file,
            filename: requestData.file.name,
        })
            .then((response: { filename: string }) => {
                setMediaFromUploadedMedia(mediaFromSpec!, response.filename);
                setMediaBrandCheck({ ...mediaBrandCheck });
                //checkMandatoryMedias();
                checkMandatorySpecs(selectedBrand!, mediaBrandCheck);
            })
            .catch((error: any) => {
                console.log(
                    "Error subiendo imagen. Se procede a iniciar tarea en Vault"
                );
                setIsNotConection(true);
                getBase64(requestData.file, (imageUrl: any) => {
                    setMediaFromBase64(mediaFromSpec!, imageUrl);
                    setMediaBrandCheck({ ...mediaBrandCheck });
                });
                addFileToBox(requestData.file, boxName);
            });
    } else {
        addFileToBox(requestData.file, boxName);
        getBase64(requestData.file, (imageUrl: any) => {
            setMediaFromBase64(mediaFromSpec!, imageUrl);
            setMediaBrandCheck({ ...mediaBrandCheck });
        });
    }
};

const addFileToBox = (file: any, boxName: string) => {
    Vault.getBox(boxName).withStuff(file.name, file);
}

export function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}

const setMediaFromUploadedMedia = (media: MediaModel, filaname: string) => {
    media!.mediaUrl = filaname;
    media!.loading = false;
    media!.checked = true;
};

const setMediaFromBase64 = (media: MediaModel, base64: string) => {
    media!.base64Content = base64;
    media!.loading = false;
    media!.checked = true;
};

const _doUpload = (requestData: { file: any; filename: string }) => {
    return uploadFile({
        file: requestData.file,
        filename: requestData.file.name,
    });
};


const fromBase64ToFile = (base64String: string) => {
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "converted.png", { type: mime });
};