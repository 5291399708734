import { message } from "antd";
import { BrandModel } from "../../model/Brands.model";
import { MediaBrandCheckModel, MediaModel } from "../../model/Ticket.model";
import { uploadFile } from "../../services/UploadFile.service";

export const isImage = (filename: string) => {

    const ext = getExtension(filename);
    return ext === "jpg" || ext === "jpeg" || ext === "png";
};

export const isImageByType = (type: string) => {
    return type === "IMAGE" || type === "" || type === null || type === undefined;
};

export const getExtension = (filename: string) => {
    return filename.split(".").pop();
}





export const tryToUpload = (
    requestData: any,
    mediaBrandCheck: MediaBrandCheckModel,
    selectedBrand: BrandModel,
    setMediaBrandCheck: Function,
    checkMandatorySpecs: Function,
    isNotConection: boolean,
    setIsNotConection: Function,
    vaultBoxName: string,
    vaultSessionId: string
) => {

    const boxName = vaultSessionId;
    const getMediaFromSpec = (spec: string) => {
        return mediaBrandCheck.medias.find(
            (media: MediaModel) => spec === media.brandSpecification
        );
    };

    let mediaFromSpec: MediaModel | undefined = getMediaFromSpec(
        requestData.data.brandSpecification
    );

    if (!mediaFromSpec) {
        mediaFromSpec = {
            mediaUrl: "",
            brandSpecification: requestData.data.brandSpecification,
            checked: false,
            loading: true,
            filename: requestData.file.name,
            mediaType: isImage(requestData.file.name) ? "IMAGE" : "VIDEO"
        };
        mediaBrandCheck.medias.push(mediaFromSpec);
    } else {
        mediaFromSpec.mediaType = isImage(requestData.file.name) ? "IMAGE" : "VIDEO";
        //mediaFromSpec.filename = requestData.file.name;
        mediaFromSpec.loading = true;
    }

    setMediaBrandCheck({ ...mediaBrandCheck });

    if (!isNotConection) {
        doUpload({
            file: requestData.file,
            filename: requestData.file.name,
        })
            .then((response: { filename: string }) => {
                console.log("response :::: " + response.filename);
                setMediaFromUploadedMedia(mediaFromSpec!, response.filename);
                setMediaBrandCheck({ ...mediaBrandCheck });
                checkMandatorySpecs(selectedBrand!, mediaBrandCheck);
            })
            .catch((error: any) => {
                console.log(
                    "Error subiendo imagen. Se procede a iniciar tarea en Vault"
                );
                setIsNotConection(true);
                getBase64(requestData.file, (fileUrl: any) => {
                    setMediaFromBase64(mediaFromSpec!, fileUrl);
                    setMediaBrandCheck({ ...mediaBrandCheck });
                });
            });
    } else {
        getBase64(requestData.file, (imageUrl: any) => {
            setMediaFromBase64(mediaFromSpec!, imageUrl);
            setMediaBrandCheck({ ...mediaBrandCheck });
        });
    }
};

export const beforeUpload = (file: any) => {
    const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "video/mp4" ||
        file.type === "video/webm" ||
        file.type === "video/ogg" ||
        file.type === "video/avi" ||
        file.type === "video/mkv";
    if (!isJpgOrPng) {
        message.error("You can only upload image or video file!");
    }
    return isJpgOrPng;
}


export function getBase64(file: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
}

const setMediaFromUploadedMedia = (media: MediaModel, filaname: string) => {
    media!.mediaUrl = filaname;
    media!.loading = false;
    media!.checked = true;
    media!.uploaded = true;
    // media!.mediaType = isImage(ext) ? "IMAGE" : "VIDEO";
};

const setMediaFromBase64 = (media: MediaModel, base64: string) => {
    media!.base64Content = base64;
    media!.loading = false;
    media!.checked = true;
    media!.uploaded = false;
};

export const doUpload = (requestData: { file: any; filename: string }) => {
    return uploadFile({
        file: requestData.file,
        filename: requestData.file.name,
    });
};


export const fromBase64ToFile = (base64String: string, filename: string) => {
    var arr: string[] = base64String.split(',');
    if (arr && arr[0]) {
        var mime = arr[0].match(/:(.*?);/)![1];

        var bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
};