import { get } from "../services/Base.service";
import { Stuffs } from "./Stuffs";
import { TaskStateType, Task } from "./Task";
import { Vault } from "./Vault";
import { VaultLogger } from "./VaultLogger";
import { VaultStorage } from "./VaultStorage";

export class Box {
    hasTask(taskName: string): unknown {
        return this.tasks.filter(task => task.name === taskName).length > 0;
    }

    public name!: string;
    public createdAt!: Date;
    public tasks!: Task[];
    public stuffs: Stuffs = new Stuffs();
    public loading: boolean = false;

    public constructor(name: string) {
        this.name = name;
        this.tasks = [];
        this.createdAt = new Date();
    }

    public static fromName(name: string): Box {
        VaultLogger.logs("Creating box from name: " + name);
        return new Box(name);
    }

    public getTask(taskName: string) {
        return this.tasks.find(job => job.name === taskName);
    }

    public withStuff(key: string, stuff: any) {
        this.saveThisStuff(key, stuff);
        return this;
    }

    public saveThisStuff(key: string, stuff: any) {
        this.stuffs.putStuff(key, stuff);
        VaultStorage.update();
    }

    public withStuffs(stuffs: Stuffs) {
        this.stuffs = new Stuffs();

        for (const key in stuffs.stuff) {
            if (!this.stuffs.isExistsStuff(key)) {
                this.stuffs.putStuff(key, stuffs.stuff[key]);
            }
        }

        return this;
    }

    public withStuffIfDoesntExists(key: string, stuff: any) {
        const existingStuff = this.stuffs?.getStuff(key);

        if (!existingStuff) {
            this.withStuff(key, stuff);
        } else {
            VaultLogger.logs("This stuff: " + key + " alreay exsists in box: " + this.name + " Im not adding it again...");
        }

        return this;
    }

    public addTask(task: Task, forceAdd: boolean = false) {
        if (forceAdd) {
            VaultLogger.logs("Forcaaddtask activated! Adding task: " + task.name + " to box: " + this.name);
            this.tasks.push(task);
            VaultStorage.update();
        } else {
            const existingTask = this.getTask(task.name);

            if (!existingTask) {
                VaultLogger.logs("Adding task: " + task.name + " to box: " + this.name);
                this.tasks.push(task);
                VaultStorage.update();
            } else {
                throw Error("This task: " + task.name + " alreay exsists in box: " + this.name);
            }
        }

        Vault.getInstance().asignWorkersToTasks();
        return this;
    }

    public addTaskIfDoesntExists(task: Task) {

        const existingTask = this.getTask(task.name);

        if (!existingTask) {
            VaultLogger.logs("Adding task: " + task.name + " to box: " + this.name);
            this.tasks.push(task);
            VaultStorage.update();
            return this;
        } else {
            VaultLogger.logs("This task: " + task.name + " alreay exsists in box: " + this.name + " Im not adding it again...");
        }


    }

    public addJsonTasks(taskJson: any[]) {
        VaultLogger.logs("Adding task from json to box: " + this.name);
        this.tasks = [...taskJson.map(jobJson => {
            return Task.fromJSON(jobJson);
        })];
        return this;
    }

    public cancelJob(jobName: string) {
        const job: Task | undefined = this.getTask(jobName);
        if (job) {
            job.cancel();
            this.removeFinishedTask(job);
        }
        VaultStorage.update();
    }

    private removeFinishedTask(task: Task) {
        VaultLogger.logs("Tryigin to remove job: " + task.name + " from box: " + this.name + " with state: " + task.state);
        if (task.state === TaskStateType.FINISHED || task.state === TaskStateType.CANCELED) {
            this.tasks = this.tasks.filter(j => j.name !== task.name);
        } else {
            VaultLogger.logs("Job: " + task.name + " not finished, keep waiting...");
        }
        VaultStorage.update();
    }


    /**
     * @deprecated The method should not be used in the future
     */
    private async isThereConnection(): Promise<boolean> {
        const ping: boolean = await get<string>(`${process.env.REACT_APP_TICKET_MANAGER_URL}/ping`)
            .then((res: any) => {
                return res === "pong"
            })
            .catch(() => false);
        return ping;
    }

    public async runTasks() {
        return new Promise<any>(async (resolve, reject) => {
            this.loading = true;
            if (this.tasks && this.tasks.length > 0) {
                const isThereConnection: boolean = await this.isThereConnection().catch(() => false);

                if (isThereConnection) {
                    VaultLogger.logs("There is connection to api, I hope I have tasks to do...");
                    VaultLogger.logs("Triying to run tasks in box: " + VaultLogger.toPretty(this));
                    for (let task of this.tasks) {
                        await this.runTask(task)
                        .catch(error => {
                            reject(error);
                        });
                    }
                    VaultStorage.update();
                    this.loading = false;
                    resolve(true);
                } else {
                    VaultLogger.logs("Yo have task, but I cant connect with the api. Im just waiting for this to continue...");
                    this.loading = false;
                    reject("No connection to api!");
                }
            } else {
                VaultLogger.logs("No jobs in box: " + this.name);
                if (Vault.deleteEmptyBoxes) {
                    VaultLogger.logs("Deleting empty box: " + this.name);
                    Vault.removeBox(this.name);
                }
                this.loading = false;
                VaultStorage.update();
                resolve(true);
            }
            this.loading = false;
        });

    }

    public async runTask(task: Task) {
        const tasks = await task.run(this.stuffs)
            .catch(error => {
                throw Error(error);
            });
        if (task.state === TaskStateType.FINISHED) {
            VaultLogger.logs("Triying to run tasks in box: " + this.name);
            this.removeFinishedTask(task);
        } else if (task.state === TaskStateType.RUNNING) {
            VaultLogger.logs("This task is already runnig. I keep witing...");
        } else if (task.state === TaskStateType.ERROR) {
            VaultLogger.logs("Wait a momment, this task is in error state. I will try to run it again in the future...");
        }
        return tasks;
    }

}

