import {
  DashboardOutlined,
  EditOutlined,
  MenuOutlined,
  SearchOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Affix, Button, Drawer, Layout, Menu } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "./App.css";
import { UserModel } from "./model/User.model";
import { SearchBrands } from "./pages/admin-brands/SearchBrands";
import SearchCenters from "./pages/admin-centers/SearcCenters";
import AdminUsers from "./pages/admin-users/AdminUsers";
import CreateTicket from "./pages/create-ticket/CreateTicket";
import { StatessContext, useStates } from "./services/Ticket.service";
import Dashboard from "./pages/dashboard/Dashboard";
import SearchTickets from "./pages/search-tickets/SearchTickets";
import Signin from "./pages/signin/Signin";
import AdminVault from "./pages/vault/AdminVault";
import ViewRequeriment from "./pages/view-ticket/ViewTicket";
import {
  canCreateTicket,
  getUser,
  isUserAdmin,
  setupAutoRefreshToken,
  singOut,
} from "./services/Auth.service";
import { setupInterceptors } from "./services/Interceptor";
import { RolesContext, useGetRoles } from "./services/Roles.service";
import { Vault } from "./vault/Vault";
import { TicketModel } from "./model/Ticket.model";
import UpdateTicket from "./pages/update-ticket/UpdateTicket";
import HeaderComponent from "./components/header/Header";
import { useClock } from "./services/clockHook";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { CenterContext, useCenters } from "./services/Center.service";

const { Sider, Content } = Layout;
const signout = () => {
  singOut();
  window.location.href = "/";
};
setupInterceptors(signout);
setupAutoRefreshToken();
Vault.init();

const App: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [ticketContext, setTicketContext] = useState<TicketModel>(
    {} as TicketModel
  );
  const ticketContextValue = {
    ticket: ticketContext,
    setTicket: setTicketContext,
  };
  const [user, setUser] = useState<UserModel | null>(null);
  const { roles } = useGetRoles();
  const { states } = useStates();
  const { getCenters, centers } = useCenters();
  const [selectedKeys, setSelectedKeys] = useState<string>("");

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const getSlider = () => {
    return collapsed ? null : (
      <Sider
        className="d-none d-md-block sider"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className={!collapsed ? "logo" : "logo-xs"} />
        <Menu
          theme="light"
          defaultSelectedKeys={[isUserAdmin() ? "0" : "2"]}
          selectedKeys={[selectedKeys]}
          onSelect={(key) => {
            setSelectedKeys(key.key);
          }}
          mode="inline"
        >
          {isUserAdmin() ? (
            <Menu.Item
              className="menuOption"
              key="0"
              icon={<DashboardOutlined />}
            >
              <Link to="/">Reportes</Link>
            </Menu.Item>
          ) : null}
          {canCreateTicket() ? (
            <Menu.Item className="menuOption" key="1" icon={<EditOutlined />}>
              <Link to="/create">Crear ticket</Link>
            </Menu.Item>
          ) : null}
          <Menu.Item className="menuOption" key="2" icon={<SearchOutlined />}>
            <Link to="/search">Buscar ticket</Link>
          </Menu.Item>
          {isUserAdmin() ? (
            <Menu.Item className="menuOption" key="3" icon={<UserOutlined />}>
              <Link to="/users">Usuarios</Link>
            </Menu.Item>
          ) : null}
          {isUserAdmin() ? (
            <Menu.Item
              className="menuOption"
              key="4"
              icon={<AppstoreAddOutlined />}
            >
              <Link to="/centers">Centros</Link>
            </Menu.Item>
          ) : null}
          {isUserAdmin() ? (
            <Menu.Item
              className="menuOption"
              key="5"
              icon={<CheckSquareOutlined />}
            >
              <Link to="/brands">Evidencias</Link>
            </Menu.Item>
          ) : null}
          <Menu.Item
            className="menuOption"
            key="6"
            icon={<ClockCircleOutlined />}
          >
            <Link to="/vault">Sin conexión</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  };

  useEffect(() => {
    setUser(getUser());
    if(getUser()){
      getCenters();
    }
  }, []);

  return !getUser() ? (
    <GoogleReCaptchaProvider reCaptchaKey="6Lei1isgAAAAAMLdxFjI8o9tcidkwMiry-ij9pcT">
      <Signin />
    </GoogleReCaptchaProvider>
  ) : (
    <Fragment>
      <Layout>
        {getSlider()}
        <Layout className="site-layout">
          <Affix offsetTop={0}>
            <div>
              <HeaderComponent
                toggle={toggle}
                signout={signout}
                setDrawerVisible={setDrawerVisible}
                user={user}
                // hours={hour}
                // minutes={minute}
                // seconds={second}
              />
            </div>
          </Affix>
          <Content
            className="site-layout-background"
            style={{
              //margin: "24px 16px",
              padding: 20,
              minHeight: 280,
            }}
          >
            <RolesContext.Provider value={roles}>
              <StatessContext.Provider value={states}>
                <CenterContext.Provider value={centers}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        isUserAdmin() ? <Dashboard /> : <SearchTickets />
                      }
                    />
                    <Route path="/create" element={<CreateTicket />} />
                    <Route path="/edit/:id" element={<UpdateTicket />} />
                    <Route path="/search" element={<SearchTickets />} />
                    <Route path="/brands" element={<SearchBrands />} />
                    <Route path="/centers" element={<SearchCenters />} />
                    <Route path="/users" element={<AdminUsers />} />
                    <Route path="/vault" element={<AdminVault />} />
                    {/* <Route path="/brand/:id" element={<EditBrand />} /> */}
                    <Route path="/view/:id" element={<ViewRequeriment />} />
                  </Routes>
                </CenterContext.Provider>
              </StatessContext.Provider>
            </RolesContext.Provider>
          </Content>
        </Layout>
      </Layout>
      <Drawer
        title="Menú"
        width={"80%"}
        placement="right"
        onClose={() => {
          setDrawerVisible(false);
        }}
        visible={drawerVisible}
      >
        <div className="row mb-5">
          <Menu mode="inline">
            {isUserAdmin() ? (
              <Menu.Item key="0" icon={<DashboardOutlined />}>
                <Link
                  onClick={() => {
                    setDrawerVisible(false);
                  }}
                  to="/"
                >
                  Reportes
                </Link>
              </Menu.Item>
            ) : null}
            {canCreateTicket() ? (
              <Menu.Item
                onClick={() => {
                  setDrawerVisible(false);
                }}
                key="1"
                icon={<EditOutlined />}
              >
                <Link to="/create">Crear ticket</Link>
              </Menu.Item>
            ) : null}
            <Menu.Item
              onClick={() => {
                setDrawerVisible(false);
              }}
              key="2"
              icon={<SearchOutlined />}
            >
              <Link to="/search">Buscar ticket</Link>
            </Menu.Item>
            {isUserAdmin() ? (
              <Menu.Item
                onClick={() => {
                  setDrawerVisible(false);
                }}
                key="3"
                icon={<UserOutlined />}
              >
                <Link to="/users">Administrar usuarios</Link>
              </Menu.Item>
            ) : null}
            {isUserAdmin() ? (
              <Menu.Item
                onClick={() => {
                  setDrawerVisible(false);
                }}
                key="4"
                icon={<UserOutlined />}
              >
                <Link to="/centers">Administrar Centros</Link>
              </Menu.Item>
            ) : null}
            {isUserAdmin() ? (
              <Menu.Item
                onClick={() => {
                  setDrawerVisible(false);
                }}
                key="5"
                icon={<UserOutlined />}
              >
                <Link to="/brands">Evidencias</Link>
              </Menu.Item>
            ) : null}
            <Menu.Item
              className="menuOption"
              onClick={() => {
                setDrawerVisible(false);
              }}
              key="6"
              icon={<ClockCircleOutlined />}
            >
              <Link to="/vault">Sin conexión</Link>
            </Menu.Item>
          </Menu>
        </div>
        <div className="row justify-content-end align-items-center mb-2">
          {
            "Bienvenido " + user?.fullName.split(" ")[0]
            // + `, son las ${hour}.${minute}.${second}`
          }
        </div>
        <div className="row justify-content-end align-items-center">
          <Button
            type="default"
            danger
            onClick={signout}
            className="w-auto mx-1"
          >
            Salir
          </Button>
        </div>
      </Drawer>
      {/* <Affix offsetBottom={0}>
        <div className="container d-block d-lg-none">
          <div className="col">
            <div className="row justify-content-end">
              <Link className="w-auto" to="/create">
                <Button
                  className="mx-3 mb-2 floatingAction"
                  type="primary"
                  shape="circle"
                  size="large"
                >
                  <FileAddOutlined />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Affix> */}
    </Fragment>
  );
};

export default App;
