import { Form, Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { BrandModel } from "../../model/Brands.model";
import { PutHook, usePost, usePut } from "../../services/Base.service";
import SpecList from "./SpecList";

export function EditBrand(props: {
  isUpdate: boolean;
  brandToUpdate: BrandModel;
  //setBrandToUpdate: Function;
  isModalOpen: boolean;
  isLoadingUpdateBrand: boolean;
  setIsLoadingUpdateBrand: Function;
  setIsModalOpen: Function;
  getAll: Function;
}) {
  const [form] = Form.useForm();
  const postHook = usePost<BrandModel>("brand");
  const putHook: PutHook = usePut<BrandModel>("brand");
  const [brandToUpdate, setBrandToUpdate] = useState<BrandModel>();

  useEffect(() => {
    setBrandToUpdate({ ...props.brandToUpdate });
    props.setIsLoadingUpdateBrand(false);
    form.resetFields();
  }, [props.isModalOpen]);

  useEffect(() => {
    form.resetFields();
  }, [brandToUpdate]);

  const updateOrCreateBrand = (values: any) => {
    if (props.isUpdate) {
      brandToUpdate!.name = values.name;
      brandToUpdate!.description = values.description;
      putHook.use(brandToUpdate).then((brand: BrandModel) => {
        props.getAll();
        props.setIsModalOpen(false);
        props.setIsLoadingUpdateBrand(false);
        message.success("Marca actualizada");
      });
    } else {
      const brand: BrandModel = {
        name: values.name,
        description: values.description,
      };
      brand.specifications = brandToUpdate!.specifications;
      postHook.use(brand).then((brand) => {
        props.setIsModalOpen(false);
        props.getAll();
        message.success("Marca Creada");
      });
    }
  };

  return (
    <Modal
      width="100%"
      style={{ top: "10px" }}
      title={`${props.isUpdate ? "Editar" : "Crear"} marca ${
        props.isUpdate ? brandToUpdate?.name : ""
      }`}
      visible={props.isModalOpen}
      confirmLoading={props.isLoadingUpdateBrand}
      onOk={() => {
        props.setIsLoadingUpdateBrand(true);
        form.submit();
      }}
      onCancel={() => {
        props.setIsModalOpen(false);
        form.resetFields();
      }}
    >
      {brandToUpdate ? (
        <Form
          onFinish={(values) => {
            updateOrCreateBrand(values);
          }}
          className="h-auto"
          layout="vertical"
          name="basic"
          form={form}
        >
          <Form.Item
            label="Nombre de la marca"
            initialValue={brandToUpdate.name}
            name="name"
            rules={[{ required: true, message: "El campo es obligatorio!" }]}
          >
            <Input onChange={(event: any) => {
              brandToUpdate.name = event.target.value;
            }} />
          </Form.Item>
          <Form.Item
            label="Descripción de la marca"
            name="description"
            initialValue={brandToUpdate.description}
            rules={[{ required: true, message: "El campo es obligatorio!" }]}
          >
            <Input onChange={(event: any) => {
              brandToUpdate.description = event.target.value;
            }}/>
          </Form.Item>

          {brandToUpdate ? (
            <SpecList
              brandToUpdate={brandToUpdate}
              setBrandToUpdate={setBrandToUpdate}
              isModalOpen={true}
              getAll={props.getAll}
            />
          ) : null}
        </Form>
      ) : null}
    </Modal>
  );
}
