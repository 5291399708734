import { AxiosResponse } from "axios";
import { delete_, get, post, put } from "../services/Base.service";
import { Stuffs } from "./Stuffs";
import { VaultLogger } from "./VaultLogger";

export class TaskType {
    public static AXIOS = 'AXIOS';
    public static WORKER = 'WORKER';
}


export class Task {
    public name!: string;
    public state!: TaskStateType;
    public createdAt!: Date;
    public updatedAt?: Date;
    public startedAt!: Date;
    public finishedAt?: Date;
    public error?: string;
    public progress!: number;
    public result!: string;
    public logs!: string;
    public url?: string;
    public method?: string;
    public payload?: any;
    public worker?: TaskWorker;
    public callBack?: TaskCallBack;
    public type!: TaskType;
    public withoutWorker: boolean = false;

    //public headers?: any;

    protected constructor(name: string) {
        this.name = name;
        this.state = TaskStateType.PENDING;
        this.createdAt = new Date();
        this.progress = 0;
    }

    public static fromAxios(name: string, response: AxiosResponse): Task {
        if (!name) {
            throw new Error('Name is required');
        }
        const task = new Task(name);

        task.type = TaskType.AXIOS;
        task.url = response.request.url;
        task.method = response.request.method;
        task.payload = response.request.data;
        return task;
    }

    public static fromData(name: string, url?: string, method?: string, payload?: any): Task {
        if (!name) {
            throw new Error('Name is required');
        }
        const task = new Task(name);
        task.type = TaskType.AXIOS;
        task.url = url;
        task.method = method;
        task.payload = payload;
        return task;
    }

    public static fromWorker(taskName: string = "Default worker", worker: TaskWorker): Task {
        const task = new Task(taskName);
        task.type = TaskType.WORKER;
        task.worker = worker;
        return task;
    }

    

    public static fromJSON(jobJson: any): Task {
        VaultLogger.logs("Creating job from json: " + VaultLogger.toPretty(jobJson));
        const task = new Task(jobJson.name);

        task.url = jobJson.url;
        task.method = jobJson.method;
        task.payload = jobJson.data;
        task.type = jobJson.type;

        return task;
    }

    public callCallBack(stuffs: Stuffs, response?: any, error?: boolean) {
        if (this.callBack) {
            this.callBack(stuffs, response, error)
        }
    }

    public withCallBack(callBack: TaskCallBack): Task {
        this.callBack = callBack;
        return this;
    }

    public withWorker(worker: TaskWorker): Task {
        this.worker = worker;
        return this;
    }

    private finishOkThisJob(stuffs: Stuffs, response: any) {
        VaultLogger.logs("Job: " + this.name + " finished ok");
        this.state = TaskStateType.FINISHED;
        this.finishedAt = new Date();
        this.progress = 100;
        this.callCallBack(stuffs, response);

    }

    private finishNoOkThisJob(stuffs: Stuffs, response?: any) {
        VaultLogger.logs("Job: " + this.name + " finished with ERRORS! Oh! no!");
        this.state = TaskStateType.ERROR;
        this.updatedAt = new Date();
        this.progress = 0;
        this.callCallBack(stuffs, response, true);
    }

    public cancel() {
        VaultLogger.logs("Canceling job: " + VaultLogger.toPretty(this));
        this.state = TaskStateType.CANCELED;
        this.updatedAt = new Date();
        this.progress = 0;
    }


    public async run(stuffs: Stuffs): Promise<Task> {
        if (this.state === TaskStateType.FINISHED ||
            this.state === TaskStateType.RUNNING) {
            return this;
        }

        VaultLogger.logs("Running job: " + VaultLogger.toPretty(this));
        this.state = TaskStateType.RUNNING;
        this.startedAt = new Date();

        if (this.type === TaskType.WORKER) {
            VaultLogger.logs(`Running task [${this.name}] with worker.`);
            if (this.worker) {
                VaultLogger.logs("Worker exsists, executing it...");

                await Promise.resolve(await this.worker(stuffs))
                    .then((result: WorkerResponse) => {
                        if (!result.error) {
                            this.finishOkThisJob(stuffs, result);
                        } else {
                            this.finishNoOkThisJob(stuffs, result);
                        }
                    })
            } else {
                this.withoutWorker = true;
                VaultLogger.warn("No worker found for task: " + this.name + ". Maybe you forgot to set it?");
            }
            return this;

        } else {
            VaultLogger.logs("Worker doesnt exists, Try to execute https request...");
            switch (this.method) {
                case 'get':
                    VaultLogger.logs("Executing get job : " + this.name);
                    await get(this.url!)
                        .then(response => {
                            VaultLogger.logs("Response from job: " + this.name);
                            VaultLogger.logr(response)
                            this.finishOkThisJob(stuffs, response);
                        });
                    return this;
                case 'post':
                    VaultLogger.logs("Executing post job : " + this.name);
                    await post(this.url!, this.payload)
                        .then(response => {
                            VaultLogger.logr(response)
                            this.finishOkThisJob(stuffs, response);
                        }).catch(error => {
                            VaultLogger.logr(error)
                            this.finishNoOkThisJob(stuffs);
                        });
                    return this;
                case 'put':
                    VaultLogger.logs("Executing put job : " + this.name);
                    await put(this.url!, this.payload)
                        .then(response => {
                            VaultLogger.logr(response)
                            this.finishOkThisJob(stuffs, response);
                        });
                    return this;
                case 'delete':
                    VaultLogger.logs("Executing delete job : " + this.name);
                    await delete_(this.url!)
                        .then(response => {
                            VaultLogger.logr(response)
                            this.finishOkThisJob(stuffs, response);
                        });
                    return this;
                default:
                    throw new Error('Method not supported');
            }
        }
    }
}

export type TaskWorker = ((stuffs?: Stuffs) => WorkerResponse) | ((stuffs: Stuffs) => Promise<WorkerResponse>);

//export type WorkerResponse = {response?: any, error: boolean}
export type TaskCallBack = (stuffs?: Stuffs, response?: any | undefined, error?: boolean) => void;


export class WorkerResponse {
    public response?: any;
    public error: boolean = false;
    public errorMessage?: string;

    public static noError(response?: any): WorkerResponse {
        return { response, error: false };
    }

    public static withError(response?: any): WorkerResponse {
        return { response, error: true };
    }
}

export class TaskStateType {
    public static PENDING = 'PENDING';
    public static RUNNING = 'RUNNING';
    public static FINISHED = 'FINISHED';
    public static ERROR = 'ERROR';
    public static CANCELED = 'CANCELED';

}