import { Button } from "antd";





function GoBackStateButton(props: {
    onClick?: () => void
}) {
    return (<Button
        danger
        className={"w-auto"}
        onClick={props.onClick}
        style={{ border: 'solid 1px; red' }}
        type="primary"
        size="large"
    >
        <p style={{ color: 'white' }}>
            {'< '}Devolver ticket
        </p>
    </Button>);
}

export default GoBackStateButton;