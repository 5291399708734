import axios from "axios";
import { UserModel } from "../model/User.model";
import { get } from "./Base.service";
import { getMyUserFromApi } from "../pages/admin-users/User.service";
import { message } from "antd";


export function setupAutoRefreshToken() {
    setInterval(() => { refreshToken() }, 60 * 1000);
}

export async function singIn(email: string, password: string) {
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_USER_MANAGER_URL}/auth/signin`,
        data: { email, password }
    })
        .then(async (response) => {
            await setToken(response.data.token, true);
        })
        .catch(error => {
            if (error.response.status) {
                message.error("Nombre de usuario o contraseña inválidos");
            }
            throw error;
        });
}

export function singOut() {
    setToken(null);
    setUser(null);
}

export const refreshToken = () => {
    return get<string>(`${process.env.REACT_APP_USER_MANAGER_URL}/auth/refresh`)
        .then((token: any) => {
            setToken(token.token);
        })
}

export const isUserAdmin = (): boolean => {
    return getUser()?.roles.includes('Admin')!;
}

export const isAsesorServicios = (): boolean => {
    return getUser()?.roles.includes('AsesorServicios')!;
}


export const canCreateTicket = (): boolean => {
    return isAsesorServicios();
}

export const canEditTicket = (): boolean => {
    return isAsesorServicios();
}

export function getToken() {
    return localStorage.getItem('token');
}


export const getUser = (): UserModel | null => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user);
    }
    return null;
}

const setToken = async (token: string | null, loadUserData: boolean = false) => {
    if (token) {
        localStorage.setItem('token', token);
    } else {
        localStorage.removeItem('token');
    }

    if (loadUserData && token) {
        await getMyUserFromApi()
            .then((user: UserModel) => {
                setUser(user);
            });
    }
}

const setUser = (user: UserModel | null) => {
    if (user) {
        const userData: { email: string, fullName: string } = user;
        localStorage.setItem('user', JSON.stringify(userData));
    } else {
        localStorage.removeItem('user');
    }

}