export class VaultLogger {

    public static active: boolean = false;

    public static logs(message: string) {
        if (VaultLogger.active) {
            console.log("Vault: " + VaultLogger.getNow() + message);
        }
    }

    public static error(message: string) {
        if (VaultLogger.active) {
            console.error("Vault: " + VaultLogger.getNow() + message);
        }
    }

    public static warn(message: string) {
        if (VaultLogger.active) {
            console.warn("Vault: " + VaultLogger.getNow() + message);
        }
    }

    public static logr(response: any) {
        if (VaultLogger.active) {
            if (response) {
                if (response.status) {
                    VaultLogger.logs("Response status: " + VaultLogger.getNow() + response.status);

                }
                if (response.data) {
                    VaultLogger.logs((VaultLogger.getNow()) + " " + JSON.stringify(response.data));
                } else {
                    try {
                        VaultLogger.logs(VaultLogger.getNow() + " " + JSON.stringify(response));
                    } catch (e) {
                        VaultLogger.logs((VaultLogger.getNow()) + "Error triying to log the response data: " + e);
                    }
                }
            }
        }
    }

    public static getNow(): string {
        return new Date().toLocaleString() + ": ";
    }

    public static toPretty(entity: any): string {
        return "\n" + JSON.stringify(entity, null, 2);
    }
}