import { Checkbox, DatePicker, Input, Select, Typography } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { CenterModel } from "../../model/CenterModel";
import { SearchTicketModel } from "../../model/SearchTicketModel";
import { isUserAdmin } from "../../services/Auth.service";
import { findCenters } from "../../services/Center.service";
import { StatessContext } from "../../services/Ticket.service";
import StateBadgeColor from "../state-tag/StateBadgeColor";

const { Text } = Typography;
const { RangePicker } = DatePicker;

function SearchTicketFilters(props: {
  searchModel: SearchTicketModel;
  setSearchModel: Function;
  showStateSelection?: boolean;
  showGetMines?: boolean;
}) {
  const dateFormat = "DD-MM-YYYY";
  const states = useContext(StatessContext);
  const [centers, setCenters] = useState<CenterModel[]>([]);

  useEffect(() => {
    findCenters().then((centers) => {
      setCenters(centers);
    });
  }, []);

  return (
    <Fragment>
      <div className="row justify-content-center">
        <div className="w-100">
          Buscar por nombre de ticket, nombre o mail del asesor, descripción de
          la falla, respuestos solicitados o correlativo:
        </div>
        <div>
          <Input
            allowClear={true}
            onChange={(event) => {
              props.setSearchModel({
                ...props.searchModel,
                searchInput: event.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="row mt-3 justify-content-center">
        {isUserAdmin() && props.showStateSelection ? (
          <div className="col-12 mb-3">
            Estado:
            <Select
              mode="multiple"
              onChange={(event) => {
                props.setSearchModel({ ...props.searchModel, states: event });
              }}
              allowClear={true}
              style={{ width: "100%" }}
            >
              {states.map((state: any) => {
                return (
                  <Select.Option key={state.state} value={state.state}>
                    <StateBadgeColor state={state.state} /> {state.state}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        ) : null}

        <div className="col-12">
          Centro:
          <Select
              onChange={(value) => {
              props.setSearchModel({ ...props.searchModel, center: value });
            }}
            allowClear={true}
            style={{ width: "100%" }}
          >
            {centers.map((center: CenterModel) => {
              return (
                <Select.Option key={center.id} value={center.id}>
                  {center.name} -{" "}
                  <Text type="secondary">{center.description}</Text>
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">Fecha desde / hasta:</div>
        <div className="col-12">
          <div className="row px-3">
            <RangePicker
              format={dateFormat}
              onChange={(moments: any) => {
                if (moments) {
                  props.setSearchModel({
                    ...props.searchModel,
                    dateFrom: moments[0].startOf("day").toDate().toISOString(), //+  "T00:00:00.000Z",
                    dateTo: moments[1].startOf("day").toDate().toISOString(), //+ "T23:59:59.999Z",
                  });
                } else {
                  props.setSearchModel({
                    ...props.searchModel,
                    dateFrom: null,
                    dateTo: null,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
      {props.showGetMines ? (
        <div className="row mt-3">
          <div className="col-12">
            <div className="row px-3">
              <Checkbox
                checked={props.searchModel.getMines || false}
                onChange={(event) => {
                  console.log(event.target.checked);
                  props.searchModel.getMines = event.target.checked;
                  props.setSearchModel({ ...props.searchModel });
                }}
              >
                Traer los míos
              </Checkbox>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

export default SearchTicketFilters;
