import { TicketModel } from "../../../../model/Ticket.model";
import { RoleModel } from "../../../../model/Role.model";
import { getLastState } from "../../../../services/Ticket.service";
import { StateModel } from "../../../../model/State.model";

export type BarCharModel = {value: number; name: string; itemStyle: any };

export const getBarDataByState = (tickets: TicketModel[], states: StateModel[]): BarCharModel[] => {
    console.log("tickets", tickets)
    console.log("states", states)

    return states
    .map(state => {
        const ticketOfState = tickets.filter(t => {
            const lastState = getLastState(t)!;
            return lastState.state === state.state;
        })

        let name = state.state.split("_").map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()).join(" ");

        return {
            name,
            itemStyle: {color: state.color},
            value: ticketOfState?.length || 0
        }
    })
}

export const getBarDataByRoles = (tickets: TicketModel[], roles: RoleModel[]): BarCharModel[] => {
    console.log("tickets", tickets)
    console.log("roles", roles)

    return roles
    .filter(role => role.role !== "Admin" && 
    role.role !== "User" && 
    role.role !== "Supervisor")
    .map(role => {
        const ticketOfRole = tickets.filter(t => {
            const lastState = getLastState(t)!;
            return lastState.role === role.role;
        })

        return {
            name: role.role,
            itemStyle: {color: role.color},
            value: ticketOfRole.length || 0
        }
    })
}