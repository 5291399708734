import { BrandModel } from "../model/Brands.model";
import { delete_, get } from "./Base.service";

export function deleteBrandFromApi(brand: BrandModel){
    return delete_(`${process.env.REACT_APP_TICKET_MANAGER_URL}/brand?id=${brand.id}`);
}


export async function getBrandById(brandId: string): Promise<string>{
    return await get(`${process.env.REACT_APP_TICKET_MANAGER_URL}/brand/name/${brandId}`);
}
