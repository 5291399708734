import { ReloadOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CenterModel } from "../../model/CenterModel";
import { TicketModel } from "../../model/Ticket.model";
import { findCenters } from "../../services/Center.service";
import { Box } from "../../vault/Box";
import { Stuffs } from "../../vault/Stuffs";
import { WorkerResponse } from "../../vault/Task";
import { Vault } from "../../vault/Vault";
import ViewTicket from "../view-ticket/ViewTicket";
import VaultCard from "./component/VaultCard";
import ViewTicketNoconnection from "./component/view-ticket/ViewTicket";

const boxNameTest = "box-test";
const taskNameTest = "task-get-estados-test";
const worker = async (stuffs?: Stuffs) => {
  const centers: CenterModel[] = await findCenters();
  return WorkerResponse.noError(centers);
};

const callBack = (stuff?: Stuffs, response?: any | WorkerResponse) => {
  if (!response.error) {
    message.info(
      "Hola desde el callBack:" +
        stuff?.getStuff("fullName") +
        "\n\n . Mostrando otra data: \n" +
        JSON.stringify(stuff?.getStuff("other")) +
        "\n\n . Este es el response: \n" +
        JSON.stringify(response, null, 2)
    );
  } else {
    message.error("Se ha producido un error: " + response.response);
  }
};

//Vault.addWorkerForTask(boxNameTest, taskNameTest, worker, callBack);

function AdminVault() {
  const [boxes, setBoxes] = useState<Box[]>(Vault.getInstance().boxes);
  const navigate = useNavigate();

  const getEmptyContent = () => {
    let areTasks: boolean = false;
    for (let box of boxes) {
      if (box.tasks.length !== 0 && !areTasks) {
        areTasks = true;
      }
    }

    if (!areTasks) {
      return (
        <div className="row mt-3">
          <div className="col-12">
            <div className="row text-center">
              <div className="col-12">No hay trabajos pendientes</div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="col">
      <div className="row">
        <h4 className="w-auto">Tickets sin conexión</h4>
      </div>
      <div className="row justify-content-end px-2">
        <Button
          onCanPlay={() => {
            navigate(`/vault`, { replace: true });
          }}
          className="w-auto"
          type="primary"
        >
          <ReloadOutlined />
          Refrescar{" "}
        </Button>
      </div>

      <div className="row">{getEmptyContent()}</div>

      {boxes.map((box) => {
        return box.tasks.length === 0 ? null : (
          <div className="row pt-3 p-1" key={box.name}>
            {" "}
            <div className="col">
              <VaultCard box={box} setBoxes={setBoxes} />
            </div>
            ;
          </div>
        );
      })}

      
      {/* <div className="row p-3">
        <InputNumber
          min={1}
          defaultValue={Vault.getInstance().secondsToRunTasks}
          onChange={(value) => {
            Vault.getInstance().secondsToRunTasks = value;
            Vault.getInstance().clearTimer();
            Vault.getInstance().setIntervalTime(value);
            VaultStorage.update();
          }}
        />
      </div>
      <div className="row p-3">
        <Button type="primary" onClick={() => Vault.getInstance().reset()}>
          Reload Vault{" "}
        </Button>
      </div>
      <div className="row">
        <Button
          size="large"
          className="w-100 mt-3"
          type="primary"
          onClick={() => {
            Vault.getBox("test")
              .withStuff("TestStuff", { nombre: "Victor Avila" })
              .addTask(
                Task.fromData(
                  "get estados",
                  `${process.env.REACT_APP_TICKET_MANAGER_URL}/center/`,
                  "get"
                ).withCallBack((stuff?: Stuffs, response?: any) => {
                  message.info(
                    "Hola:" + stuff?.getStuff<any>("TestStuff").nombre
                  );

                  message.info("Hola desde el callback");
                })
              );
          }}
        >
          Agregar trabajo sin conexión http call
        </Button>
        <Button
          danger
          size="large"
          className="w-100 mt-3"
          type="primary"
          onClick={() => {
            Vault.getBox("test")
              .withStuff("TestStuff", { nombre: "Victor Worker" })
              .addTask(
                Task.fromWorker("get estados", async (stuffs?: Stuffs) => {
                  const centers: CenterModel[] = await findCenters();
                  console.log("Estas son los centers:");
                  console.log(centers);
                  console.log(
                    `STUFFS: ${JSON.stringify(
                      stuffs?.getStuff("TestStuff"),
                      null,
                      2
                    )}`
                  );
                  return WorkerResponse.withError(
                    "Este es el mensaje de error"
                  );
                }).withCallBack(
                  (stuff?: Stuffs, response?: any | WorkerResponse) => {
                    !response.error
                      ? message.info(
                          "Hola desde el callBack:" +
                            stuff?.getStuff<any>("TestStuff").nombre +
                            ". Este es el response: /n" +
                            JSON.stringify(response, null, 2)
                        )
                      : message.error(
                          "Se ha producido un error: " + response.response
                        );
                  }
                )
              );
          }}
        >
          Agregar trabajo sin conexión Worker NOK
        </Button>

        <Button
          size="large"
          className="w-100 mt-3"
          type="primary"
          onClick={() => {
            Vault.getBox("test")
              .withStuff("TestStuff", { nombre: "Victor Worker" })
              .addTask(
                Task.fromWorker("get estados", async (stuffs?: Stuffs) => {
                  const centers: CenterModel[] = await findCenters();
                  return WorkerResponse.noError(centers);
                }).withCallBack(
                  (stuff?: Stuffs, response?: any | WorkerResponse) => {
                    !response.error
                      ? message.info(
                          "Hola desde el callBack:" +
                            stuff?.getStuff<any>("TestStuff").nombre +
                            ". Este es el response: /n" +
                            JSON.stringify(response, null, 2)
                        )
                      : message.error(
                          "Se ha producido un error: " + response.response
                        );
                  }
                )
              );
          }}
        >
          Agregar trabajo sin conexión Worker OK
        </Button>

        <div className="col-3">
          <Button
            size="large"
            className="w-100 mt-3"
            type="primary"
            onClick={() => {
              Vault.getBox(boxNameTest).withStuff("fullName", "Victor Avila");
            }}
          >
            Agregar Nombre
          </Button>
        </div>
        <div className="col-3">
          <Button
            size="large"
            className="w-100 mt-3"
            type="primary"
            onClick={() => {
              Vault.getBox(boxNameTest).withStuff("other", {
                email: "mito@email.com",
                signo: "libra",
              });
            }}
          >
            Agregar Agregar apellido
          </Button>
        </div>
        <div className="col-6">
          <Button
            size="large"
            className="w-100 mt-3"
            type="primary"
            onClick={() => {
              Vault.getBox(boxNameTest).addTask(
                Task.fromWorker(taskNameTest, worker).withCallBack(callBack)
              );
            }}
          >
            Agregar trabajo sin conexión Worker OK y recuperación de worker
          </Button>
        </div>
      </div> */}
    </div>
  );
}

export default AdminVault;
