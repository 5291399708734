import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "./Auth.service";

export const RolesContext = createContext([]);

export const findRole = (roles: any, role: string) => {
    return roles.find((r: any) => r === role);
}

export function useGetRoles() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [roles, setRoles] = useState<any>([]);
    const user = getUser();
    const reuse = () => {
        setLoading(true);
        const fetchData = () => {
            if (user) {
                try {
                    axios(`${process.env.REACT_APP_USER_MANAGER_URL}/user/roles`)
                        .then((result: any) => {
                            setRoles(result.data);
                            setLoading(false);
                        })
                } catch (error) {
                    setError(true);
                }
            } else {
                setLoading(false);
                setError(false);
            }
        };
        fetchData();
    };

    useEffect(reuse, []);

    return { loading, error, reuse, roles };

}