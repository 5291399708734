import axios from "axios";
import { useEffect, useState } from "react";

export type PostHook = {
    data: any;
    loading: boolean;
    error: boolean;
    use: Function;
};
export type PutHook = {
    data: any;
    loading: boolean;
    error: boolean;
    use: Function;
};
export type GetHook = {
    data: any;
    loading: boolean;
    error: boolean;
    reuse: Function;
};
export type DeleteHook = {
    loading: boolean;
    error: boolean;
    use: (id: string) => Promise<any>;
};

export function useGet<S>(path: string, i: S | S[] = [] as S[]): GetHook {
    const [data, setData] = useState<S | S[]>(i);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const reuse = (): Promise<any> => {
        setLoading(true);
        const fetchData = async () => {
            try {
                await axios(`${process.env.REACT_APP_TICKET_MANAGER_URL}/${path}`)
                    .then(result => {
                        setData(setKeys(result.data))
                        setLoading(false);
                    })
            } catch (error) {
                setError(true);
            }
        };
        return Promise.resolve(fetchData());
    };

    useEffect(() => { reuse() }, []);

    return { data, loading, error, reuse };
}

export function useDelete(path: string): DeleteHook {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const use = (id: string) => {
        setLoading(true);
        const runDelete = () => {
            try {
                return axios(
                    {
                        method: "delete",
                        url: `${process.env.REACT_APP_TICKET_MANAGER_URL}/${path}?id=${id}`
                    })
                    .then(result => {
                        if (result) {
                            setLoading(false);
                        } else {
                            setError(true);
                        }
                    })
            } catch (error) {
                setError(true);
                return Promise.reject(error);
            }
        };
        return runDelete();
    };

    return { loading, error, use };
}

export function usePut<S>(path: string) {
    const [data, setData] = useState<S | null>(null);
    const [loading, setLoading] = useState(false);
    const use = (requestBody: S) => {
        setLoading(true);
        try {
            return put<S>(`${process.env.REACT_APP_TICKET_MANAGER_URL}/${path}`, requestBody)
                .then((result: S) => {
                    setData(result)
                    setLoading(false);
                    return result;
                })
        } catch (error) {
            setError(true);
        }
    };
    const [error, setError] = useState(false);

    return { data, loading, error, use }
}

export function usePost<S>(path: string) {
    const [data, setData] = useState<S | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState(false);
    const use = (requestBody: any) => {
        setLoading(true);
        try {
            return post(`${process.env.REACT_APP_TICKET_MANAGER_URL}/${path}`, requestBody)
                .then((result: any) => {
                    setData(result);
                    setLoading(false);
                    return result;
                });
        } catch (error) {
            setError(true);
            return Promise.reject(error);
        }
    };
    return { data, loading, error, use }
}

export const setKeys = (data: any | any[]): any => {
    if (Array.isArray(data)) {
        data.forEach((item: any, index: number) => {
            doSetKeys(item);
        });
    } else {
        doSetKeys(data);
    }

    return data;
}

const doSetKeys = (data: any | any[]): any => {

    if (data.id) {
        data.key = data.id;
    } else {
        data.key = new Date().getTime();
    }


    return data;
}


export function put<S>(url: string, data: any): Promise<S> {
    try {
        return axios({
            method: 'put',
            url,
            data: { ...data }
        }).then(response => response.data);
    } catch (error) {
        throw error;
    }

}

export function post<S>(url: string, data: any): Promise<S> {
    return axios({
        method: 'post',
        url,
        data: { ...data }
    }).then(response => response.data);
}

export function get<S>(url: string): Promise<S> {
    return axios({
        method: 'get',
        url
    }).then(response => {
        return response.data
    });
}

export function delete_<S>(url: string): Promise<S> {
    return axios({
        method: 'delete',
        url
    }).then(response => {
        return response.data
    });
}