import { BrandModel } from "../../model/Brands.model";
import { TicketModel } from "../../model/Ticket.model";
import { get } from "../../services/Base.service";
import { getTicketById } from "../../services/Ticket.service";

export const getTicketFromApi = async (ticketId: string): Promise<TicketModel> => {
    return getTicketById(ticketId);
}

export const getBrands = () => {  
    return get<BrandModel[]>(`${process.env.REACT_APP_TICKET_MANAGER_URL}/brand/`);

}