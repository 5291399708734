import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { UserModel } from "../../model/User.model";

const { Header } = Layout;

function HeaderComponent(props: {
  toggle: () => void;
  signout: () => void;
  setDrawerVisible: (visible: boolean) => void;
  user: UserModel | null;
  // hours: number;
  // minutes: number;
  // seconds: number;
}) {
  const [isRolesVisible] = useState(false);

  return (
    <Fragment>
      <Header className="site-layout-background d-none d-md-block">
        <div className="row">
          <div className="col-3">
            <div className="row align-items-center h-100">
              {React.createElement(MenuOutlined, {
                className: "trigger w-auto",
                onClick: props.toggle,
              })}
            </div>
          </div>
          <div className="col m-0 p-0">
            <div className="row justify-content-end align-items-center px-3">
              <div className="w-auto">
                {isRolesVisible
                  ? props.user?.roles.map((role) => `${role} `).join(", ")
                  : null}
                {
                  "Bienvenido " + props.user?.fullName.split(" ")[0]
                  // + `, son las ${props.hours}.${props.minutes}.${props.seconds}`
                }
              </div>
              <Button
                type="default"
                danger
                onClick={props.signout}
                className="w-auto mr-3"
                icon={<UserOutlined />}
              >
                Salir
              </Button>
            </div>
          </div>
        </div>
      </Header>
      <Header className="site-layout-background d-block d-md-none">
        <div className="row">
          <div className="col-3">
            <div className="row align-items-center h-100 title-xs">
              <img src="/logo.jpg" alt="logo" />
            </div>
          </div>
          <div className="col m-0 pt-3">
            <div className="row justify-content-end align-items-center h-100">
              {React.createElement(MenuOutlined, {
                className: "w-auto",
                onClick: () => {
                  props.setDrawerVisible(true);
                },
              })}
            </div>
          </div>
        </div>
      </Header>
    </Fragment>
  );
}

export default HeaderComponent;
