import { Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { UserModel } from "../../../model/User.model";
import { useUpdatePassword } from "../User.service";
import { Buffer } from "buffer";
import { useEffect } from "react";

function ChangePassword(props: {
  isModalOpen: boolean;
  userToUpdate: UserModel;
  setIsModalOpen: Function;
}) {
  const [form] = useForm();
  const updatePassHook = useUpdatePassword();

  const finish = (value: any) => {
    if (value && value.pass === value.passr) {
      console.log("ok: " + value.pass);
      const pass: string = Buffer.from(value.pass).toString("base64");
      console.log("Pass: " + pass.toString());
      updatePassHook.use(props.userToUpdate.id!, pass.toString() + "");
      props.setIsModalOpen(false);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [props.userToUpdate]);

  return (
    <Modal
      width="100%"
      style={{ top: "10px" }}
      title={`Cambiar contraseña de usuario ${props.userToUpdate?.fullName}`}
      visible={props.isModalOpen}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        props.setIsModalOpen(false);
      }}
    >
      <Form
        onFinish={(values) => {
          finish(values);
        }}
        className="h-auto"
        layout="vertical"
        name="basic"
        form={form}
      >
        <Form.Item
          label="Contraseña"
          initialValue=""
          name="pass"
          hasFeedback
          rules={[{ required: true, message: "El campo es obligatorio!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Repita la contraseña"
          initialValue=""
          name="passr"
          dependencies={["pass"]}
          hasFeedback
          rules={[
            { required: true, message: "El campo es obligatorio!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pass") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "El password ingresado no es igual que el anterior!"
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangePassword;
